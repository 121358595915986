@import './fonts-google.less';

@font-face {
    font-family: 'Chalk';
	src:
		url('../fonts/djb_chalk_it_up-webfont.woff') format('woff'),
		url('../fonts/djb_chalk_it_up-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
