.showcart {
    .counter-number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 50%;
        background-color: @red;
        color: @white;
        width: 1.6rem;
        height: 1.6rem;
        .align-v();
        margin-top: 8px;
        right: -3px;
    }

    .icon-cart {
        .active & {
            stroke: @yellow-dark;
		}
    }
}

.block-minicart {
    padding: @padding-mobile;
    background-color: @white;

    #btn-minicart-close,
    .block-title,
    .items-total {
        display: none;
    }

    .checkout {
        .button-dark();
        width: auto;
    }

    .viewcart {
        .button();
    }

    .block-content {
        padding: 0;
    }

    .product.actions {
        margin: 0 0 0 @padding-mobile;
    }

    .minicart-summary {
        .grid-item();
        border-top: 1px solid @border-light;
        font-size: 1.4rem;
        margin: 0;

        .button {
            .button-small();
        }

        p {
            text-align: right;
        }

        .label {
            margin: @padding-mobile 0 0;
        }

        .value {
            margin: 0;
        }

        .value-netto {
            font-size: 1.6rem;
        }

        .value-brutto {
            font-size: 2.4rem;
            color: @red;
        }

        .minW(@tablet-max, {
            text-align: right;
        });
    }

    .minicart-delivery {
        .grid-item();
        text-align: right;
        font-size: 1.6rem;
        margin-bottom: @menu-height-lg;
        border-top: 1px solid @border-light;

        .label {
            margin: 0;
        }

        .value {
            margin: 0;
            color: @red;
        }

        .highlight {
            .highlight();
        }
    }

    .update-cart-item {
        padding: 0;
        margin: 0;
        opacity: 0;
        width: 1px;
        height: 1px;
        position: absolute;
    }
}

.minicart-order {
    .grid-item();
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    @media (min-width: @desktop-lg) {
        gap: 50px;
        flex-direction: row;
    }
    text-align: center;
    max-width: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 2;
    background: @white;
    border-top: 1px solid;
    padding-top: 2rem;

    .minicart-open & {
        max-width: 100%;
        text-align: center;
        background-color: @white;
        transition: max-width @transition;
        transition-delay: .1s;
    }

    .minW(@desktop-lg, {
        text-align: right;
    });
}

.minicart-wrapper {
    font-family: @font-2;
    font-weight: 700;
    width: 100%;

    .block-subtitle {
        position: relative;
    }

    .product-item-details .actions {
        position: absolute;
        top: @padding-mini;
        right: 0;
        line-height: 0;
	}
}

.minicart-items {
    margin: 0;

    .product-item {
        position: relative;
        padding: 4rem @padding-mini @padding-mobile*2;
		overflow: hidden;

        &:not(:first-child) {
            border-top: 1px solid @border-light;
        }

        &:first-child {
            padding-top: 4rem;
        }
    }

    .product-item-pricing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;

        .label {
            display: none;
        }
    }

    .delete:hover:before {
        color: @red;
    }

    .product-item-photo {
        width: 6.5rem;
        height: 6.5rem;
		float: left;
        margin-right: @padding-mobile;
		display: flex;
		align-items: center;
		justify-content: center;
        border: 1px solid @gray;

        .product-image-photo {
			width: auto;
			height: auto;
			padding: 3px;
        }
    }

    .product-item-name {
        margin-top: 0;
		font-size: 1.2rem;
        font-weight: 700;
		line-height: 1.4;
    }

    .product-item-details {
        padding-left: 0;
    }

    .cart-item-qty {
        .form-input();
        text-align: center;
        width: auto;
        padding: .5rem;
        max-width: 8rem;
        font-weight: 600;
    }

    .price-container {
        display: block;
        width: 100%;
        text-align: right;
        white-space: nowrap;
        font-size: 1.4rem;
    }
}

.minicart-message {
	font-family: @font-2;
	font-weight: 700;
	color: @text-1;
	font-size: 1.8rem;
	line-height: 1.2;
	text-align: right;

	p {
		margin: 0;
		padding-top: @padding-mobile;
		padding-bottom: @padding-mobile;
	}

    &#minicart-success-message {
        color: @success;
    }

    &#minicart-error-message {
        color: @error;
    }
}

.maxW(@desktop-lg -1px, {
    .minicart-items {
        .product-item-details {
            float: left;
        }
    }

    .product-item {
        padding: 4rem @padding-mini @padding-mobile*2;

        &:first-child {
            padding-top: 4rem;
        }
    }
});

.minW(@desktop-lg, {
	.minicart-items {
        .product-item {
			padding: @padding-mobile @padding-mobile @padding-mobile @padding-lg;

            &:first-child {
                padding-top: @padding-mobile;
            }
		}

        .product-item-details {
            display: flex;

            .actions {
                .align-v();
                left: 0;
                right: auto;
                display: flex;
                flex-direction: column;
                margin: 0;
            }
        }

        .product-item-name {
			margin-right: 1rem;
            width: 180px;
		}

		.price {
			margin-left: 1rem;
		}
	}
});

// overrides
.block-minicart {
    .block-content .minicart-order > .primary {
        margin: 0;
    }

    .block-content .minicart-order > .primary .action.checkout {
        .button-dark();
        .button-large();
        width: auto;
        margin: 0 auto;
    }

    .minicart-items-wrapper {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

