.customer-account-create {
	.page-main {
		.columns {
			max-width: @desktop-sm;
		}
	}
}

.form-create-account {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.account.create {
        width: 100%;
    }

    .control {
		margin: 1rem 0;
		flex-wrap: wrap;

		.validation-advice {
			width: 100%;
			position: static;
			text-align: right;
			padding-top: @padding-mini;
		}
	}

	.fieldset {
		width: 100%;
        margin-bottom: @padding-desktop;

        .legend {
            margin-left: 0;
            float: none;
        }

        .field {
            margin: 0;

            .label,
            .control {
                display: block;
                float: none;
                width: 100%;
                text-align: left;
            }
        }
    }

    .actions-toolbar {
        text-align: right;
		padding: @padding-mobile 0;

        .secondary a.action {
            margin-top: 0;
        }
    }

    .password-strength {
        margin-top: 1rem;
    }
}

.minW(@tablet-md, {
	.form-create-account {
		.fieldset {
			width: calc(~"50% - @{padding-tablet}");
		}
	}
});

.minW(@desktop-min, {
	.form-create-account {
		.fieldset {
			width: calc(~"33.333% - @{padding-tablet}");
		}
	}
});


.minW(@desktop-md, {
	.form-create-account {
		.fieldset {

			label {
				font-size: 1.4rem;
			}

			.password-strength {
				label {
					font-size: 1rem;
				}
			}
		}
	}
});
