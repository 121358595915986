
.breadcrumbs {
	.container;
	padding-top: @padding-mobile;
	display: none;
	font-size: 1.2rem;
    max-width: 100%;

    .item:not(:last-child):after {
        content: none;
    }

	ul {
		.list-reset;
		display: flex;
		flex-wrap: wrap;
	}

	li {
		&:last-child {
			font-weight: 700;
			color: @text-1;
		}
	}

	a {
		color: @text-2;
		text-decoration: none;

		&:hover {
			color: @red;
            text-decoration: none;
		}
	}

    strong {
        font-weight: 700;
    }

	.icon {
		display: inline-block;
		transform: rotate(180deg);
		height: 18px;
		margin: 0 1rem;
	}

	.minW(@desktop-min, {
		display: block;
	});
}
