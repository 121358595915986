body.account {
  .login-as-customer {
    display: none;
  }

  &.sales-order-history {
    .left-button-container {
      a.button {
        min-width: 200px;
        text-align: center;
      }
    }
  }

  .orders-history {
    .table-order-items {
      tr {
        td, th {
          &:last-child {
            text-align: right;
          }
          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }
}

.tt_order-order-prints {
  padding: 50px;
  @media print {
    padding: 0;
    @page {
      size: landscape;
      margin: 0;
    }

    .page-wrapper {
      margin: 1.6cm;
    }
    .table-order-items {
      width: 100%;
      a {
        text-decoration: none !important;
      }
      .nowrap {
        white-space: nowrap;
      }
      tr {
        td, th {
          &:nth-child(3) {
            text-align: center;
          }
          &:nth-child(2) {
            width: 180px;
          }
          &:nth-child(6) {
            width: 100px;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .back-to-orders, .box-actions {
    display: none;
  }

  .lac-notification-sticky {
    display: none;
  }
}
