.checkout {
	padding-top: 0;

	.btn-cart {
		.button-large();
		display: block;
		width: 100%;
		text-align: center;
	}

	.input-box {
		&:not(.checkbox-styled) {
			flex-direction: column;

			label {
				width: 100%;
			}

			.input-text {
				width: 100%;
				margin-top: @padding-mobile;
			}
		}

		&.checkbox-styled {
			label {
				font-family: @font-1;
				font-weight: 400;
				color: @text-2;
			}
		}
	}

	.input-description {
		color: @text-6;
		font-family: @font-1;
		font-weight: normal;
		font-size: 1.2rem;
	}

	.block-content {
		+ .block-title,
		+ .block-content {
			margin-top: @padding-tablet;
		}
	}

	.payment-methods,
	.shipment-methods {
		.price {
			font-family: @font-2;
		}
	}

	.validation-advice {
		width: 100%;
	}

	.checkout-box {
		border-bottom: 1px solid @rgba-black;
		margin-bottom: @padding-tablet;
	}

	.price-row {
		display: flex;
		justify-content: space-between;
		line-height: 1;
		padding-bottom: @padding-mobile;
		margin-bottom: @padding-mobile;
		font-family: @font-2;
	}

	.price-label {
		color: @text-2;
		font-size: 1.6rem;
	}

	.price {
		color: @text-1;
		font-size: 2rem;
		font-weight: 900;
		text-align: right;
	}

	.coupon-code {
		.checkout__accordion-title;
	}

	.navigate-button {
		&.disabled {
			pointer-events: none;
			background-color: @gray;
			border-color: @gray;
			color: @text-2;
		}
	}
}

.checkout__complete {
	text-align: center;

	.buttons {
		margin-top: @padding-tablet;
	}
}

.checkout__complete__heading {
	.h1;
}

.checkout__complete__number {
	.h3;
}

.checkout-grid--reversed {
	display: flex;
	flex-direction: column-reverse;
}

.error-msg {
	color: @error;
}

.maxW(@desktop-min, {
	.checkout-col + .checkout-col {
		margin-top: @padding-tablet;
	}
	.checkout-grid--reversed {
		.checkout-col + .checkout-col {
			margin-bottom: @padding-tablet;
		}
	}
});


#shipping_address .buttons {
	margin-top: @padding-tablet;
}

.checkout__product-index,
.checkout__product-remove {
	display: block;
	color: @text-2;
	font-size: 12px;
	float: left;
	font-weight: 300;
}

.removeqty {
	padding-right: 1rem;

	&:before {
        content: '';
        .icon('bin');
		background-position: 0 center;
		background-size: auto;
		width: 2rem;
		height: 1.25rem;
		display: inline-block;
	}
}

.checkout__product-image {
	padding: 3px;
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px @gray;
	float: left;
	margin-right: @padding-mobile;
	margin-bottom: @padding-mobile;
}

.checkout__accordion-title {
	color: @text-2;
	font-size: 1.6rem;
	font-family: @font-2;
	position: relative;
	cursor: pointer;

	.icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%) rotate(-90deg);
	}

	.checkout__accordion--expanded & {
		.icon {
			transform: translate(0, -50%) rotate(90deg);
		}
	}
}

.checkout__accordion-item {
	.checkout__accordion-content {
		display: none;
	}
	&.checkout__accordion--expanded {
		.checkout__accordion-content {
			display: block;
		}
	}
}

.checkout__accordion-content {
	font-family: @font-1;
}

.checkout-description {
	text-align: center;
	color: @text-6;
}

.checkout__summary {
	.addsqty, .subsqty, .removeqty {
		display: none !important;
	}

	.qty {
		input {
			pointer-events: none;
			font-size: 2rem;
			width: 100%;
		}
		&:before {
			content: 'x';
		}
	}

	.checkout-agreements {
		.validation-advice {
			bottom: 0;
		}
	}
}

.checkout {
	.btn-cart {
		.button-large();
		display: block;
		width: 100%;
		text-align: center;
		text-decoration: none;
	}
}

.checkout-cart-index {
	.cart-empty {
		.container;
		padding-top: @padding-tablet;
		padding-bottom: @padding-tablet;
		background-color: @white;
		border-radius: @radius;

		a {
			text-decoration: underline;
		}
	}
}

div.loading-ajax, div.loading-ajax-login, #loading-process {
	height: 50px;
	width: 50px;
	.align;
	position: fixed;
    text-align: center;
	z-index: 999999;
	display: none;

	.remodal-is-locked & {
		display: block;
	}
}

.minW(@desktop-lg,  {
	.checkout-cart-index {
		.cart-empty {
			padding-top: @padding-desktop;
			margin-top: @padding-tablet;
		}
	}
});

.shipping-address-title {
	.h4;
	padding-bottom: @padding-mobile;
}

#payment_form_ecard {
	table, td {
		border: 0;

		img {
			max-width: 50px;
			display: block;
		}

		.pay_col {
			img {
				max-width: 100%;
			}
		}
	}

	td {
		padding: 0;
	}
}

#billing_address_list > li > div:nth-child(1):nth-last-child(2),
#shipping_address_list ul > li > div:nth-child(1):nth-last-child(2) {
	width: calc(~"50% - @{padding-mobile}");
	flex: unset;
}

#billing_address_list,
#shipping_address_list {
	.clearfix {
		&::before,
		&::after {
			content: none;
		}
	}
}

#billing_address_list > li,
#shipping_address_list ul > .clearfix {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	flex-wrap: wrap;

	.input-box {
		flex: 1;

		+ .input-box {
			flex: unset;
			width: calc(~"50% - @{padding-mobile}");
		}
	}
}

.checkout-content {
    .content;
    box-shadow: @box-shadow-checkout-block;
}

.checkout-index-index .checkout-container {
    .opc-progress-bar {
        text-align: center;
    }

    .step-title {
        .h3;
        border: none;
    }

    .step-content {
        margin: 0;
    }

    .select {
        .nice-select;
        font-family: @font-1;
        &:focus {
            box-shadow: none;
        }
    }

    .label {
        font-family: @font-2;
        color: @text-1;
    }

    .button.primary, .action.primary {
        .button-red;
        .button-large();
    }

    .action.action-show-popup {
        .button();
    }

    .checkout-agreements {
        .action {
            color: @text-1;
            text-align: left;
        }

        .checkout-agreement {
            padding: 0;
            margin: 0 0 @padding-mobile;
        }
    }

    .opc-summary-wrapper {
        margin-top: 0;
    }

    .opc-block-shipping-information {
        // .checkout-content;

        .shipping-information-title {
            .h3;
            border: none;
        }
    }

    .checkout-payment-method {
        .payment-option-title {
            padding-top: @padding-mobile;
            padding-bottom: @padding-mobile;
            border-color: @border-light;

            .action-toggle {
                color: @text-2;
            }
        }
    }

    .payment-method-content,
    .payment-method-title {
        padding: 0;
    }

    .opc-block-summary {
        .checkout-content;
        margin-bottom: @padding-tablet;

        > .title {
            .h3;
            border: none;
        }

        .actions-toolbar {
            border-top: 1px solid @border-light;
            margin-top: -1px;

            .secondary {
                border: none;
            }
        }

        .minicart-items {
            padding: 0;

            .product-item-details {
                display: block;
            }
        }

        .product-item {
            padding: @padding-mini * 2 0;
            color: @text-1;
        }

        .product-item-name {
            font-family: @font-2;
            margin-right: 0;
        }

        .price {
            font-family: @font-2;
            font-weight: 700;
        }

        .items-in-cart {
            padding-top: 0;

            .title {
                strong {
                    font-size: 13px;
                    font-weight: 700;
                    color: @text-1;
                    text-transform: uppercase;
                }
            }
        }

        .table-totals {
            .mark {
                background-color: transparent;
                color: @text-2;
                font-family: @font-2;
                padding-left: 0;
            }

            .totals {
                border-bottom: none;
            }

            .price {
                color: @text-1;
            }
        }
    }
}

#checkoutSteps > li {
    .checkout-content;
    margin-bottom: @padding-tablet;
    margin-top: 0;
}

.maxW(@mobile-max, {
	#payment_form_ecard  {
		table {
			img {
				max-width: 50px;
			}
		}
	}
});

.minW(@mobile-max, {
	.checkoutcontainer {

		.editcart {
			width: 40px;
		}
	}
});

.minW(@desktop-min, {

	.checkout-grid {
		display: flex;
		width: 100%;
		justify-content: space-between;
		flex-wrap: wrap;

		.checkout-col {
			width: calc(~"50% - @{padding-mobile}");
		}
	}

	.checkout-grid--reversed {
		flex-direction: row-reverse;
	}

	.checkout-block {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
});

#shipping-new-address-form {
	.field {
		&[name="shippingAddress.vat_id"], &[name="shippingAddress.company"] {
			display: none;
		}
	}
}
