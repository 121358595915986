.section-banner {
    font-size: 1.6rem;

    .article {
        .container;
		margin: 0;
		color: @text-2;
		line-height: 1.6;

        .minW(@desktop-min, {
            width: 50%;
        });

        .minW(@desktop-lg, {
            width: 700px;
        });
    }

    .banner-header {
		margin-bottom: @padding-mobile;
		color: @text-1;

		.minW(@desktop-lg, {
			font-size: 5.6rem;
		});
    }

    [class^='button'] {
        margin-top: @padding-mobile;
    }
}

.banner-catalog {
	background-image: url('../images/pages/home/banner-products-m.png');
    background-repeat: no-repeat;
	// background-size: contain;
	// background-position: bottom right -4rem;
	background-image: url('../images/banners/banner-spring-md.jpg');
	background-size: cover;
	background-position: center;
	background-color: @banner-bg;
	min-height: 280px;
	display: flex;

    .minW(@desktop-min, {
        // background-image: url('../images/pages/home/banner-products.png');
        // background-size: auto 90%;
        // background-position: bottom right @padding-tablet;
	});

	.minW(@desktop-md, {
		background-image: url('../images/banners/banner-spring-lg.jpg');
	});

    .minW(@desktop-lg, {
		// background-position: bottom right @padding-desktop;
		font-size: 2rem;
		min-height: 380px;
	});


	.maxW(@tablet-max, {
		background-image: url('../images/banners/banner-spring-sm.jpg');
		min-height: 300px;
	});

    .article {
		padding-top: @padding-lg;
        padding-bottom: @padding-lg;
		max-width: 60vw;
		width: 900px;
		color: @white;
		display: flex;
		flex-direction: column;
    	justify-content: space-between;
        align-items: flex-start;

		strong {
			display: block;
		}

		.maxW(@mobile-max, {
			max-width: 80vw;
		});
    }

    .banner-header {
		font-family: @font-banner;
		text-transform: uppercase;
		font-size: 3.2rem;
		font-weight: 400;
		color: @white;
		line-height: 1.1;

		.minW(@mobile-max, {
            font-size: 6rem;
		});

		.minW(@desktop-sm, {
            font-size: 7rem;
        });

		.minW(@desktop-lg, {
            font-size: 8rem;
        });
	}

	.button-yellow {
		&:active,
		&:focus,
		&:hover {
			color: @yellow !important;
		}
	}
}

.banner-about {
    display: flex;
    flex-direction: column;
	background-color: @white;
	padding-top: @padding-lg;
	padding-bottom: @padding-lg;

    .minW(@tablet-md, {
        flex-direction: row;
		align-items: center;

		.article, .banner-image {
			flex: 1;
		}
    });

    .minW(@desktop-min, {
		flex-direction: row;
		justify-content: stretch;

		.article {
			width: 40vw;
		}

		.banner-image {
			width: 60vw;
		}
	});

	.minW(@desktop-lg, {
		.article {
			flex: .75;
		}

		.banner-image {
			flex: 1.25;
		}
    });

    .article {
		max-width: 100%;
        padding-bottom: @padding-tablet;

        .minW(@mobile-max, {
			padding-bottom: @padding-lg;
        });

        .minW(@tablet-md, {
			padding-bottom: 0;
        });

        .minW(@desktop-lg, {
            padding-bottom: @padding-lg*2;
        });

    }

    .banner-image {
		.container;
		margin: 0;
		text-align: center;
	}

	.button {
		.minW(@tablet-md, {
			margin-top: @padding-tablet;
        });
	}
}

.banner-recommended {
    .container;
	padding-top: @padding-lg;
	padding-bottom: @padding-lg;

	.maxW(@tablet-max, {
		.banner-header {
			padding-right: 130px;
			min-height: 50px;
		}
	});

    .minW(@tablet-md, {
		display: flex;
	});

	.minW(@desktop-min, {
		padding-bottom: 0;
	});

	.minW(@desktop-md, {
		// padding-top: @padding-lg+@padding-mobile;
	});

    .article {
		position: relative;
		padding-bottom: @padding-lg;
		padding-left: 0;
		padding-right: 0;

        .minW(@tablet-md, {
           width: 50%;
        });

        .minW(@desktop-min, {
            width: 33%;
         });
	}

    .slider-recommended {
        .minW(@tablet-md, {
            width: 50%;
        });

        .minW(@desktop-min, {
			width: 67%;
			padding-left: 2rem;
         });
	}

	.slider-recommended-nav {
		display: flex;
		align-items: center;
		// justify-content: center;

		.maxW(@tablet-md, {
			position: absolute;
			top: 0;
			right: 0;
		});

		.minW(@tablet-md, {
			margin-top: @padding-tablet;
		});
	}

	.owl-prev {
		margin-right: @padding-mobile;
	}

	.owl-prev, .owl-next {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: 4px solid @text-4;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: border-color @transition;
		cursor: pointer;

		.icon {
			stroke: @text-1;
			height: 15px;
			width: 10px;
			margin-left: -2px;
			transition: stroke @transition-short;
		}

		&.disabled {
			.icon {
				stroke: @text-4;
			}
		}

		&:hover{
			&:not(.disabled) {
				// border-color: @text-3;
				.icon {
					// stroke: @yellow-dark;
				}
			}

		}
	}

	.owl-next  {
		position: relative;

		.icon {
			transform: rotate(180deg);
			margin-left: 2px;
		}
	}


}

.icon-progress {
	transform: rotate(-90deg);
	position: absolute;
	top: -@progress-border;
	left: -@progress-border;

	circle {
		stroke-dashoffset: 0;
		transition: stroke-dashoffset @transition;
		stroke-width: @progress-border;
		fill: transparent;
		stroke-linecap: round;
  	}

	.owl-next & {
		.bar {
			stroke: @yellow-dark;
			stroke-linecap: round;
		}
	}
}
