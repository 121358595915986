.block-title {
	position: relative;
	z-index: 2;
	padding-left: @padding-mobile + @padding-mini;
	color: @text-1;
}

.block-content {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
	position: relative;
	margin-bottom: 0;
	background-color: @white;
    padding: 0 @padding-mobile + @padding-mini @padding-mobile + @padding-mini;

	&:before {
		content: '';
		position: absolute;
		top: -@padding-tablet;
		left: 0;
		right: 0;
		bottom: 100%;
		border-top-right-radius: @radius;
		border-top-left-radius: @radius;
	}

	&.block-content--low-heading {
		border-radius: @radius;
		padding-top: @padding-tablet;

		&:before {
			content: none;
		}

		.block-title {
			.h3;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.col-left {
	.block {
		margin-bottom: @padding-desktop;
	}
}

.sidebar-additional {

    .block {
        margin-bottom: 0;
    }

	.block-content {
		padding: 0 2rem 2rem;
		box-shadow: 0 8px 30px 4px @rgba-shadow;
	}

	.block-title {
		font-size: 2.4rem;
	}

	.button {
		margin-top: @padding-mobile;
	}
}

.block {
    padding-top: 38px;

	.block-title {
		.h1;
		padding-top: 0;
		padding-bottom: 0;
		background-color: transparent;
	}

	.block-content {
		background-color: @white;
		color: @text-1;

		&:before {
			background-color: @white;
		}

		p {
			margin-bottom: 5px;
			margin-top: 0;
		}

		&.block-content--dark {
			background-color: @block-content-dark;

			&:before {
				background-color: @block-content-dark;
			}
		}
	}
}

.block-layered-nav,
.block-cms-menu {
	padding-top: 0;

	a {
		text-decoration: none;
		display: block;
        padding: 0 @padding-mobile 0 0;

		&:hover,
		&:active,
		&:focus {
			color: @text-2;
        }

        span {
            padding: 1.2rem @padding-mobile;
            display: block;
        }
	}

	.category-img {
		display: none;
	}

	.block-title {
		.h1;
		margin-bottom: @padding-mobile;
		padding-bottom: 0;
		background-color: transparent;
		padding-top: 0;
	}

	.block-content {
		background-color: @gray;
		box-shadow: 0 15px 35px -12px @category-item-shadow;
		padding: 0 0 @padding-mobile;

		&:before {
			background-color: @gray;
		}
	}

	.label-all {
		.screen-readers;
	}

	ul {
		.list-reset;
		text-transform: uppercase;
		font-size: 1.1rem;
		line-height: 1.3;

		li {
			border-top: 1px solid @white;
            margin: 0;

			&:first-of-type {
				border-top: 0;
			}
		}

		ul {
			max-height: 0;
			overflow: hidden;

			a span {
                padding: 1rem @padding-mobile;
			}
		}

		.parent {
			border-top: 1px solid @white;

			> a {
				position: relative;
                padding-right: 30px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 12px;
                    right: @padding-mobile;
                    .icon('arrow-small-dark');
                    height: 12px;
                    width: 12px;
                    transform-origin: 50% 50%;
                    transform: rotate(180deg);
                    transition: transform @transition-short;
                }

                &:hover {
                    &:after {
                        .icon('arrow-small-active');
                        transform: rotate(270deg);
                        top: 10px;
                    }
                }
			}

			> ul {
				a {
                    transition: background-color @transition-short;

                    span {
                        padding-left: @padding-tablet;
                    }

					&:after {
						height: 9px;
						width: 9px;
					}

					&:hover {
						background-color: @main-bg;
					}
				}

				ul {
					text-transform: none;

					a {
                        span {
                            padding-left: @padding-tablet + @padding-mobile;
                        }

						&:hover {
							color: @text-1;
						}
					}

					li {
						&:not(.active) {
							a {
								color: @text-2;
							}
						}
					}
				}
			}

			&.menu-active {

				> a {
					font-weight: 700;
					color: @text-1;
					background-color: @white;

					&:after {
                        .icon('arrow-small-active');
						transform: rotate(270deg);
						top: 10px;
					}
				}

				.menu-active {
					&:not(:last-child) {
						border-bottom: 3px solid @gray;
					}
				}
			}

			li {
				border-top: 1px solid @gray;
				background-color: @white;
			}

			.menu-active {
				+ li {
					border-top: 0;
				}
			}

		}

		.has-active {
			> a {
				&:after {
                    .icon('arrow-small-active');
				}
			}
		}
	}

	.current, .active, .has-active {
		> a {
			font-weight: 700;
			color: @text-1;
			background-color: @white;
		}
	}

	.menu-active {
		> ul {
			max-height: 900px;
			height: auto;
		}
	}
}

.block-advice {

	.block-content {
		background-color: @yellow;
		font-size: 1.3rem;

		&:before {
			background-color: @yellow;
		}
	}

	.button {
		.button-white;
		display: block;
		text-align: center;
		position: relative;

		.icon {
			stroke: @yellow-dark;
			height: 16px;
			margin-bottom: -4px;
		}
	}
}

.block-article {
	box-shadow: @box-shadow-block;
	padding-top: 0;

	.block-title {
		background-color: @white;
		border-top-right-radius: @radius;
		border-top-left-radius: @radius;
		text-transform: uppercase;
		padding-top: @padding-mobile + @padding-mini;
		padding-bottom: @padding-mobile;
		font-weight: 700;
		line-height: 1.2;
		font-family: @font-1;
		font-size: 1.3rem;
		margin-bottom: 0;

		a {
			text-decoration: none;
		}
	}

	.block-content {
		overflow: hidden;
		color: @text-2;
		font-size: 1.3rem;

		.button {
			.button-dark();
			.button-small();
			float: right;
			margin-top: @padding-tablet;

			&:hover {
				border-color: @yellow;
				background-color: @yellow;
				color: @text-1;
			}
		}
	}

}

.block-videos {
	.std {
		margin-bottom: @padding-tablet;
	}
}

.block-info {

	h2 {
		font-size: 1.4rem;
		font-family: @font-1;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }
	}

	.button {
		.button-dark;
		.button-small;
		margin-top: @padding-tablet;
	}

	.buttons {
		text-align: right;
	}
}

.block-order {
	z-index: 10;

	.button {
		display: block;
		text-align: center;
	}

	.tocart {
		.button-red;
		display: block;
		width: 100%;
	}

	.box-tocart .field {
        .input-box;
		margin: 1rem 0;

		.label {
			margin-right: @padding-mobile !important;
            text-align: right !important;

            .maxW(@mobile-max, {
                text-align: left !important;
            });
		}

		input {
			max-width: 90px;
			text-align: center;
		}
	}

    .price-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:before {
            font-family: @font-1;
            color: @text-3;
            font-size: 1.2rem;
            font-weight: 300;
            text-align: left;
            display: flex;
            align-items: flex-end;
            min-height: 2.2rem;
            padding-right: @padding-mini;
        }
    }

	.price-box {
		font-weight: 700;
		text-align: right;
		padding-bottom: @padding-mobile;
		margin-bottom: @padding-mobile;
		font-family: @font-2;
		border-bottom: 1px solid @rgba-black;

		.regular-price,
		.price-excluding-tax,
		.old-price,
		.special-price {
			display: flex;
			justify-content: space-between;
			align-items: self-end;
			line-height: 1;
		}

        .price-container {
            flex-direction: column;
        }

        .price {
            font-size: 2.2rem;
            line-height: 1;
            flex: 1 1 auto;
            text-align: right;
        }

		.tax-label {
			font-size: 1.4rem;
            color: @text-1;
			display: block;
			width: 100%;
			text-align: right;
		}

		.price-including-tax {
			margin-bottom: @padding-tablet;
		}

		.price-excluding-tax {
            .price {
                font-size: 1.6rem;
            }
        }

		.old-price {
            .price {
                color: @text-2;
                font-size: 2rem;
            }

            .price-label {
                min-height: 1.6rem;
            }
		}

		.special-price {
			.price {
				font-size: 2.2rem;
				.minW(@desktop-lg, {
					font-size: 2.8rem;
				})
			}
			.price-including-tax {
				color: @red;
                padding-bottom: @padding-mobile;
			}

            .price-wrapper {
                width: 100%;
            }

            + .old-price .price-including-tax {
                position: absolute;
                top: 2.5rem;
                right: @padding-mini + @padding-mobile;
            }
		}
	}

	.time {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: @padding-mini;

		span {
			font-weight: 700;
			font-size: 1.6rem;
		}

		.delivery-label {
			color: @red;
		}

		.icon {
			stroke: @text-1;
			height: 23px;
			width: 38px;
			margin-left: @padding-mobile;
		}

	}

	.highlight {
		.highlight();
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.2rem;
		padding: 1rem;
		line-height: 1.1;

		strong {
			white-space: nowrap;
			margin-left: 1rem;
		}
	}
}

.block-order-inactive {

	.buttons {
		text-align: right;
	}

	.button {
		.button-red;
		display: block;
		width: 100%;
	}
}

.maxW(@ipad-v - 1px, {
	.block-videos {
		.videos {
			margin-left: -@padding-tablet;
			margin-right: -@padding-tablet;
		}

		.youtube {
			border-radius: 0;
		}
	}
});

.maxW(@mobile-max, {
	.block-videos {
		.videos {
			margin-left: -@padding-mobile - @padding-mini;
			margin-right: -@padding-mobile - @padding-mini;
		}
	}
});

.minW(@mobile-max, {
	.column.main {
		.block-content {
			padding-bottom: @padding-tablet;
		}

		.block-content,
		.block-title {
			padding-left: @padding-tablet;
			padding-right: @padding-tablet;
		}
	}

});

.minW(@ipad-v, {
	.block-videos {
		.block-content {
			display: flex;
			justify-content: space-between;
		}

		.std {
			padding-right: @padding-tablet;
			width: 40%;
			margin-bottom: 0;
		}

		.videos {
			width: calc(~"60% - @{padding-tablet}");
		}

	}
});

.minW(@desktop-min, {
	.sidebar-additional {
		.block {
			padding-bottom: @padding-tablet;
			padding-top: 0;

			&:first-of-type {
				padding-top: 28px;
			}
		}
	}
});

.minW(@desktop-lg, {
	.block-article {
		.block-content {
			.button {
				padding: .8rem 2.6rem;
				font-size: 1.2rem;
			}
		}
	}

	.block {
		.block-title {
			font-size: 3rem;
		}

		&.block-layered-nav,
		&.block-cms-menu {
			.block-title {
				font-size: 4rem;
			}
		}

		&.block-article {
			.block-title {
				font-size: 1.3rem;
			}
		}
	}

	.block-layered-nav,
	.block-cms-menu {

		a span {
            padding-left: @padding-mobile + @padding-mini;
            padding-right: @padding-mobile + @padding-mini;
		}

		.block-title {
			padding-left: @padding-mobile + @padding-mini;
			padding-right: @padding-mobile + @padding-mini;
			margin-bottom: .3em;
		}

		ul .parent > a:after {
			right: @padding-mobile;
		}
	}

	.block-videos {
		.std {
			font-size: 1.6rem;
			padding-right: @padding-desktop;
		}
	}

	.block-order {
		.price-box {
			.price {
				font-size: 2.8rem;
			}

			.tax-label {
				font-size: 1.6rem;
			}

			.price-label {
				min-height: 2.8rem;
			}
		}

		.highlight {
			font-size: 1.4rem;
		}
	}

	.sidebar-additional {
		.block {
			&:first-of-type {
				padding-top: 52px;
			}
		}
	}
});
