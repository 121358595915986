.label-promo() {
    background-color: @yellow;
	color: @text-1;
}

.label-new() {
    background-color: @red;
	color: @white;
}

.label-sale() {
    background-color: @gray;
    color: @text-1;
    padding-left: 2.6rem;

    &::before {
        content: '';
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        width: 20px;
        .icon('percentage');
        background-size: auto 10px;
        background-position: @padding-mini center;
    }
}

.label-bestseller() {
    background-color: @text-1;
    color: @white;
    padding-left: 2rem;

    &::before {
        content: '';
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        width: 20px;
        .icon('exclamation');
        background-size: auto 14px;
        background-position: @padding-mini center;
    }
}

.labels {
	.list-reset;

	li {
		padding: 0.15rem 1rem;
		width: auto;
		font-size: 1.2rem;
		font-weight: 900;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: @padding-mini;
		position: relative;
	}

	.promo {
		.label-promo();
	}

	.new {
		.label-new();
	}

	.sale {
		.label-sale();
	}

	.bestseller {
		.label-bestseller();
	}
}
