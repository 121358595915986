.section-search {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	background-color: @search-main-bg;
	color: @white;
	text-align: center;

	p {
		margin: @padding-mobile 0;
	}

	a {
		color: @icon-light;
	}

	.container {
		height: 100%;
		padding-top: @padding-tablet;
		padding-bottom: @padding-tablet;

		.minW(@desktop-md, {
			padding-top: 2rem;
		});
	}

	.search-title {
		color: @white;
		font-family: @font-2;
		font-size: 1.6rem;
		line-height: 1.2;
		max-height: 300px;
		transition: max-height @transition-short;
		overflow: hidden;

		.minW(@desktop-min, {
			font-size: 2rem;
		});
	}

	.input-box {
		padding: @padding-mini;
		max-width: 100%;
		margin: 1rem auto;
		position: relative;

		label {
			.screen-readers();
		}

		.minW(@desktop-md, {
			max-width: 670px;
		});

		.maxW(@mobile-max, {
			width: 100%;
		});
    }

    .input-text {
        background-color: @white;
		border-radius: 10px;
		border-bottom: none;
        width: 100%;
		min-height: 5rem;

		.minW(@desktop-md, {
			min-height: 6.5rem;
		});
	}

	.buttons {
		text-align: right;
	}

	.button-submit-form {
		.button-large();

		&:active,
		&:focus,
		&:hover {
			color: @yellow !important;
            border: 2px solid @yellow;
		}
	}

	.noUi-target {
		background-color: @ui-handle-bg;
		border-radius: @radius-sm;
	}

	.noUi-tooltip {
		color: @white;
		background-color: transparent;
		font-weight: 400;
	}

	.noUi-handle {
		background-color: @yellow-dark;
	}

	.noUi-connect {
		background-color: @slider-gradient-bottom;
	}

	.multirange {
		margin-top: 4rem;

		.maxW(@mobile-max, {
			margin-top: 4rem;
		});
	}

	.minW(@ipad-v, {
		&.autocomplete-visible {
			justify-content: flex-start;

			.search-title {
				max-height: 0;
				transform: scale(0);
			}
		}
	});

	.minW(@desktop-min, {
		position: absolute;
		top: 0;
		right: 0;
		width: @main-search-width;
		height: calc(~"100vh - @{slider-cat-h}");
		min-height: @search-min-h-lg;
		max-height: 600px;
		padding-top: @menu-height + 10px;
	});

	.minW(@desktop-sm, {
		height: calc(~"100vh - @{slider-cat-h}");
		min-height: @search-min-h-lg;
	});

	.minW(@desktop-lg, {
		padding-top: @menu-height-lg;
		height: calc(~"100vh - @{slider-cat-h-lg}");
		min-height: @search-min-h-lg;
		width: @main-search-width-lg;
		justify-content: center;
	});
}

.search-button {
    &:hover,
    &:active,
    &:focus {
        border: 0;
    }
}
