.customer-account-login {
    .login-container {
        .block {
            .block-title {
                border: 0;

                strong {
                    .h3;
                }
            }
        }

        .block-customer-login {
            width: 100%;
        }

        .block-new-customer {
            display: none;
        }
    }
}
