.product-list-tpms {
    .sorter,
    .toolbar,
    .toolbar-bottom,
    .toolbar-sorter {
		display: none;
    }

	.item, .product-item {
        &:not(.visible) {
            display: none;
        }
	}
}

.skip-tpms {
	position: absolute;
}

.header-tpms-buttons {
	position: absolute;
	left: 60px;
	z-index: 10;

	.button-tpms {
		max-width: 100px;
		white-space: normal;
		text-align: center;
		font-size: 1rem;
		padding-right: 1rem;
		padding-left: 1rem;

		&:hover, &:focus, &:active {
			color: @text-1;
		}
	}

	.maxW(@tablet-max, {
		display: none;
		top: 10px;

		.menu-open & {
			display: block;
		}

		.button-tpms {
			max-width: 58px;
			font-size: 1rem;
		}
	});

	.minW(@mobile-md, {
		right: 220px;
		left: auto;

		.button-tpms {
			max-width: 70px;

			+ .button-tpms {
				max-width: 105px;
			}
		}
    });

    .maxW(450px, {
        right: auto;
        left: 50px;

        .button-tpms.button-orange {
			max-width: 95px;
		}
    });

	.minW(@desktop-min, {
		top: 9px;
	});

	.maxW(@desktop-md - 1px, {
		.button-tpms {
			padding-bottom: 2px;
			padding-top: 2px;
		}
	});

	.minW(@desktop-min, {
		left: 600px;
		right: auto;

		.menu-open &, .account-open & {
			.link-hidden;
		}
	});

	.minW(@desktop-md, {
		left: 610px;

		.button-tpms,
		.button-tpms + .button-tpms {
			max-width: 160px;
		}
	});

	.minW(@desktop-lg, {
		left: 690px;
		top: 20px;

		.button-tpms {
			font-size: 1.1rem;
		}
	});
}

.tpms-params,
.tpms-info {
	&:not(.visible) {
		display: none;
	}
}

.tpms-params {
	display: flex;
	flex-wrap: wrap;
	padding: @padding-mobile;

	b {
		color: @text-1;
	}
}

.tpms-info {
	padding-right: @padding-mobile;
	padding-bottom: 1rem;
	width: 100%;

	.value {
		word-break: break-word;
	}
}

.tpms-year + .select2-container--disabled {
	visibility: hidden;
}

.minW(@tablet-md, {
	.tpms-params {

	}

	.tpms-info {
		width: 33.333%;
	}
});
