@mobile-menu-offset: @menu-height + @search-height - 2px;

.nav-primary {
    top: @menu-height + @search-height - 2px;
    font-family: @font-2;
    font-size: 1.4rem;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;

    a {
		display: block;
		padding: .8rem @padding-mobile;
		.transition(color .2s);

		.icon {
			.transition(stroke .2s);
			.minW(@desktop-min, {
				display: block;
			});
		}

		&:hover {
			.icon {
				stroke: @red;
			}
		}
    }

    li {
        .transition(opacity .2s);
        margin-bottom: 0;
    }

    ul {
        top: 39px;
        background-color: @white;

        a {
            color: @text-2;
        }
	}

	.label-all {
		display: none;
	}

	.menu-open & {
		.menu-active, .current {
			> a {
				color: @red;
			}
		}

		.icon {
			stroke: @red;
		}
	}

}

.nav-8-1 > a { // promocja urodzinowa
	color: @red !important;
	font-weight: 700;
}

.category-img {
	height: 110px;
	width: 110px;
	margin: @padding-mini @padding-mobile @padding-mobile;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.maxW(@tablet-max, {
	.nav-primary {
		.translate-hide();
		background-color: @white;
		overflow: hidden;

		.menu-open & {
			.translate-show();
			height: calc(~"100vh - @{menu-height} - @{search-height}");
			width: 100vw;
			overflow: hidden;
			position: fixed;
		}

		.active {
			> a {
				color: @red;
			}
		}

		.level0 {
			overflow: hidden;
		}

		.menu-active {
			overflow: hidden;

			> .level0 {
				overflow: hidden;
			}
		}

		.current {
			overflow: hidden !important;

			> .level0 {
				position: fixed;
				overflow-y: auto !important;
				top: 130px !important;
                border-top: 1px solid @border-light;

				> li {
					&:first-child {
						border-top: none;
					}
				}
			}
		}

		.icon {
			display: none;
		}

		li {
			background-color: @white;
			border-top: 1px solid @border-light;
			transition-delay: .2s;
		}

		ul {
			.translate-hide();
			height: auto;
			width: 100vw;
			overflow-x: hidden;
		}

		.parent {
			position: relative;
			padding-right: 30px;

			&:after {
				content: '';
				.align-v();
                right: @padding-mobile;
                .icon('arrow-small-dark');
				height: 14px;
				width: 14px;
				transform-origin: 50% 25%;
				transform: rotate(180deg);
			}

			&.menu-active {
				&:after {
                    .icon('arrow-small-active');
				}
			}

			> a {
				pointer-events: none;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&.menu-active {
				position: fixed;
				top: 91px;
				right: 0;
				left: 0;
				padding-left: 2rem;
				z-index: 100;

				&:after {
					left: @padding-mobile;
					transform: rotate(0deg);
					top: 13px;
				}

				> ul {
					top: 39px;
					.translate-show();
					bottom: 0;
				}
			}
		}
	}

	.category-img,
	.level-top.last {
		display: none;
	}
});

.maxW(@ipad-v - 1, {
    .nav-primary {
        top: @mobile-menu-offset;

        .parent.menu-active {
            top: @mobile-menu-offset;
        }

		.current {
			> .level0 {
                top: 166px !important;
            }
        }
    }
});

.minW(@mobile-max, {
	.nav-primary {
		a {
			padding-left: @padding-tablet;
			padding-right: @padding-tablet;
		}

		.menu-active, .current {
			> a {
				.label-all {
					display: inline;
				}
			}
		}

		.parent {
			&:after {
				right: @padding-tablet;
			}

			&.menu-active {
				&:after {
					left: @padding-tablet;
				}
			}
		}
	}
});

.minW(@desktop-min, {

	.nav-primary {
		position: absolute;
		top: 0;
		right: 280px;
		left: 510px;
		font-family: @font-1;
		display: flex;
        align-items: center;

		a {
			padding-left: @padding-mobile;
			padding-right: @padding-mobile;
		}

		.active {
			.icon {
				&:not(.icon-arrow-small) {
					stroke: @red;
				}
			}
		}

		.nav-contact {
			order: 0;
		}

		.nav-products {
			order: 1;

			.icon-arrow-small {
				transform: rotate(-90deg);
				margin-left: @padding-mobile;
				transition: stroke @transition;
			}

			.icon-products {
				height: 3rem;
			}

			> ul {
				transform: translateY(20px);
				.transition(transform @transition-short, opacity @transition-short;);
				opacity: 0;
				position: fixed;
				top: @menu-height;
				left: @padding-desktop;
				right: @padding-desktop;
				max-height: 0;
				overflow: hidden;
				display: flex;
				justify-content: stretch;
				border-radius: 10px;
				max-width: 1200px;
				margin-left: auto;
				margin-right: auto;

				.menu-open & {
					transform: translateY(0);
					opacity: 1;
					max-height: 800px;
				}

				> li {
					border-bottom: 1px solid @border-light;
					flex: 1;
					line-height: 1.1;

					> a {
						text-align: center;
						text-transform: uppercase;
						color: @text-2;
						font-size: 1.2rem;
						cursor: pointer;
						position: relative;

						&:after {
							content: '';
							position: absolute;
							bottom: -1px;
							left: 0;
							right: 0;
							border-bottom: 2px solid @red;
							transform: scaleX(0);
							.transition(transform .3s);
						}
					}

					> ul {
						position: absolute;
						top: @menu-height;
						right: 0;
						left: 0;
						z-index: -1;
						opacity: 0;
						height: 0;
						.transition(opacity .2s);
						transition-delay: .2s;
						display: flex;
						flex-wrap: wrap;
						overflow: hidden;

						> li {
							// display: inline-block;
							// vertical-align: top;
							width: 25%;
							padding: @padding-mobile;
							border-right: 1px solid @border-light;

							> a {
								text-transform: uppercase;
								color: @text-1;
								font-weight: 900;
							}

							&.active {
								> a {
									text-decoration: underline;
								}
							}

							a {
								padding: .5rem 0;

								&:hover {
									text-decoration: underline;
								}
							}

							> ul {
								padding-left: @padding-mobile;

								a {
									padding: .5rem 0;
									&:hover {
										color: @red;
									}
								}

								.active {
									> a {
										color: @red;
									}
								}
							}
						}
					}

					&.menu-active {
						background-color: @category-active-bg;

						> a {
							color: @red;

							&:after {
								transform: scaleX(1);
							}
						}
						> ul {
							z-index: 99;
							opacity: 1;
							height: auto;
							// min-height: 100%;
							z-index: 10;
							top: @menu-height;
							max-height: 800px - @menu-height;
							max-height: calc(~"100% - @{menu-height}");
							overflow-y: auto;
						}
					}

					&.active {
						background-color: @category-active-bg;

						> a {
							color: @red;
						}
					}

					&:hover {
						background-color: @category-active-bg;

						> a {
							color: @red;

							&:after {
								transform: scaleX(1);
							}
						}
					}
				}
			}

			.menu-open & {
				background-color: @white;

				> a {
					color: @text-1;
				}

				.icon-arrow-small {
					transform: rotate(90deg);
					stroke: @icon-light;
				}
			}
		}

		> li {
			position: static;

			> a {
				display: flex;
				align-items: center;
				height: @menu-height;
			}
		}

		.menu-open & {
			> li {
				&:not(.nav-products) {
					.link-hidden;
				}
			}
		}

		.account-open & {
			> li {
				.link-hidden;
			}
		}

		.menu-active, .current {
			> a {
				.label-all {
					display: none;
				}
			}
		}
	}

	.nav-contact {
		ul {
			display: none;
		}
	}

	.icon-products, .icon-phone, .icon-contact, .icon-mail {
		max-width: 26px;
		max-height: 26px;
		margin-right: 1rem;
		width: 26px;
		height: 26px;

		.minW(@desktop-lg, {
            max-width: 30px;
			max-height: 30px;
			width: 30px;
			height: 30px;
			margin-right: @padding-mobile;
        });
	}

	.account-open {
		.skip-account {
			background-color: @white;
		}
	}
});


// menu height

.minW(@desktop-lg, {

	.nav-primary {
		left: 590px;

		> li {
			> a {
				height: @menu-height-lg;
			}
		}

		.nav-products {
			> ul {
				top: @menu-height-lg;
				max-width: 1400px;

				> li {

					&.menu-active {
						> ul {
							top: @menu-height-lg;
							max-height: calc(~"100% - @{menu-height-lg}");
						}
					}

					> a {
						font-size: 1.4rem;
					}

					> ul {
						top: @menu-height-lg;
					}
				}
			}
		}

		.icon-contact {
			width: 30px;
			height: 30px;
		}
	}

	.skip-links,
	.skip-phone,
	.page-header .container {
		min-height: @menu-height-lg;
	}

	.icon-phone {
		height: 30px;
		width: 30px;
	}

	.menu-open, .minicart-open, .account-open {
		.main-container {
			&:before {
				height: @menu-height-lg;
			}
		}
	}

	.header-account {
		top: @menu-height-lg;
	}

	body {
		padding-top: @menu-height-lg;
	}

});
