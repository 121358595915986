.sales-order-print {
  a.logo {
    max-width: 1085px;
    margin: 0 auto;
    padding-right: 4rem;
    padding-left: 4rem;
    display: block;
    img {
      max-width: 300px;
    }
  }
  .copyrights, .copyright {
    display: none;
  }
}
