.messages {
    .align();
    .list-unstyled;
    position: fixed;
    width: 600px;
    max-width: 100%;
    z-index: 102;
    font-weight: 700;
    padding: @padding-mobile !important;

    .notice-msg {
        padding: @padding-mobile;
    }

    .message {
        padding: @padding-tablet @padding-tablet @padding-tablet @padding-tablet + @padding-mobile;
    }

    .message,
    .notice-msg {
        border-width: 2px;
        border-style: solid;
        border-radius: @radius;
        background-color: @white;
        box-shadow: @box-shadow-message;
        cursor: pointer;

        &:before,
        ul li:before {
            content: none !important;
        }

        > *:first-child:before {
            top: 50%;
            left: @padding-mini;
        }
    }

	.message-success {
		border-color: @success;
        color: @success;

        > *:first-child:before {
            color: @success;
        }
	}

	.message-error {
		border-color: @error;
        color: @error;

        > *:first-child:before {
            color: @error;
        }
	}
}
