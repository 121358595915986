@pagination-size: 40px;

.pages {
    width: 100%;

    .pages-items {
        .list-reset;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1.6rem;
        margin: auto;
    }

    .page span {
        font-size: 1.6rem;
        color: @pagination;
    }

    .page,
    .page span,
    .action,
    .action:before {
        text-align: center;
        border: 0;
        width: 100%;
        line-height: @pagination-size;
    }

    .action.next,
    .action.previous {
        margin: 0;
    }

    .item {
        height: @pagination-size;
        width: @pagination-size;
        border-radius: 50%;
        margin: 1rem;
        font-weight: 900;
        transition: background-color @transition-short;

        &.current, &:hover {
            background-color: @text-1;

            .page span,
            .action:before {
                color: @white;
            }
        }
    }
}
