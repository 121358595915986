.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: @white;
  z-index: 100;
  transition: height @transition, background-color @transition-short;
  color: @text-1;
  border-bottom: 1px solid @border-light;

  .menu-open &, .account-open & {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    transition: none;

    .minW(@desktop-min, {
      border: 0 none;
      overflow: hidden;
    });
  }

  .minW(@desktop-min, {
    .menu-open &,
    .account-open & {
      background-color: transparent;
    }
  });

  ol, ul {
    list-style: none;
    padding: 0;
  }

  a:not([class^='button']) {
    color: @text-1;
  }

  .skip-link {
    &:not(.skip-cart) {
      .minW(@desktop-min, {
        color: @text-1;
      });
    }

    &.skip-account {
      .cms-home.menu-scrolled &,
      .cms-home.account-open & {
        color: @text-1;
      }
    }
  }

  .container {
    height: @menu-height;

    .menu-open &, .account-open & {
      top: 0;
      position: relative;

      .maxW(@tablet-max, {
        border-bottom: 1px solid @border-light;
      });
    }
  }

  .logo {
    height: 22px;
    width: 120px;
    display: block;
    transition: height @transition, width @transition;
    .align();
    z-index: 10;
    margin: 0;

    .maxW(@tablet-max, {
      .menu-open & {
        display: none;
      }
    });

    .minW(@desktop-min, {
      .align-v();
      left: @padding-tablet;

      .menu-open &, .account-open & {
        .link-hidden;
      }
    });

    .minW(@desktop-md, {
      width: 140px;
      height: 24px;
    });

    .minW(@desktop-lg, {
      width: 180px;
      height: 32px;
      left: @padding-desktop;
    });
  }
}

.skip-link {
  .icon {
    stroke: @text-1;

    .minW(@desktop-min, {
      stroke: @icon-light;
    });
  }

  &:hover {
    .icon {
      stroke: @red;
    }
  }
  .icon-phone {
    display: flex;
    width: auto;
    height: auto;
    border: 0;
    border-radius: unset;
  }
}

.skip-links {
  position: relative;
  min-height: @menu-height;
  width: 100%;
  font-size: 1.4rem;

  .label {
    white-space: nowrap;
  }

  .minW(@desktop-min, {
    display: flex;
    align-items: center;
    margin-left: 145px;
  });

  .minW(@desktop-md, {
    margin-left: 160px;
  });

  .minW(@desktop-lg, {
    margin-left: 200px;
  });
}

.skip-nav {
  .align-v();
  left: 0;
  margin: 0;
  z-index: 10;

  .icon {
    display: block;
    z-index: 101;
    height: 18px;
    width: 30px;

    span {
      display: block;
      height: 0;
      width: 100%;
      border-bottom: 2px solid @text-1;
      transform-origin: 50% 50%;
      transition: all @transition;
      margin-bottom: 6px;

      &:first-child {
        .menu-open & {
          transform: translateY(8px) rotate(45deg);
        }
      }

      &:nth-child(2) {
        .menu-open & {
          opacity: 0;
        }
      }

      &:last-child {
        .menu-open & {
          transform: translateY(-8px) rotate(-45deg);
        }
      }

      .menu-open & {
        border-color: @yellow-dark;
      }
    }

    .minicart-open & {
      display: none;
    }
  }

  .label {
    .screen-readers();
  }
}

.skip-phone {
  .icon {
    margin-bottom: 5px;
  }
  .minW(@desktop-min, {
    height: @menu-height;
    .menu-open &, .account-open & {
      .link-hidden;
    }
  });
}

.skip-phone,
.skip-contact,
.skip-products,
.skip-mail {
  display: none;

  .icon {
    margin-right: @padding-mobile;
    transition: stroke .2s linear;
  }

  .minW(@desktop-min, {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  });
}

.skip-phone,
.skip-mail {
  .icon {
    margin-right: 5px;
    color: @icon-light;
  }

  &:hover {
    .icon {
      color: @red;
    }
  }
}

.skip-contact, .skip-products {
  &:hover {
    .icon {
      stroke: @red;
    }
  }
}

.maxW(@tablet-max, {
  .minicart-open {
    .logo {
      display: none;
    }
  }
});

.minW(@desktop-min, {
  .page-header {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .skip-nav .icon {
    display: none;
  }

});

@keyframes menu-transition {
  0% {
    transform: translateY(-@menu-height-lg);
  }
  100% {
    transform: translateY(0);
  }
}

.mobile-call {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: @gray;

  .minicart-open & {
    height: 0;
    overflow: hidden;
  }

  .maxW(@ipad-v - 1, {
    display: flex !important;
  });

  .icon-phone {
    .icon;
    height: 18px;
    width: 22px;
    stroke: @text-1;
    margin-right: @padding-mini;
  }
}

.phone-link {
  display: flex;
  align-items: center;
}
