.skip-account {
	display: none;

    .menu-open & {
        display: flex;
		align-items: center;
		height: @menu-height;
    }

    .minW(@desktop-min, {
		display: flex;
		align-items: center;
		padding-left: @padding-mobile;
		padding-right: @padding-mobile;

        .minicart-open & {
            display: none;
		}

		.menu-open & {
			.link-hidden;
		}
	});
}

.skip-account {
    .align-v();
    right: 0;
	z-index: 10;

	.label {
		line-height: 1.1;
		white-space: normal !important;
		font-size: 1.2rem;
		max-width: 130px;
		height: calc(~"@{menu-height} - 1rem");
		max-height: @menu-height;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: right;
	}

	.icon {
		margin-left: 1rem;

		&:first-of-type {
			order: 3;
		}
	}

    .icon-user {

		.minW(@desktop-min, {
			height: 28px;
			width: 23px;
		});

		.maxW(@tablet-max,  {
			.menu-open & {
				stroke: @yellow-dark;
			}
		});
    }

    .icon-arrow-small {
        transform: rotate(-90deg);
    }

    &:hover {
        .icon-arrow-small  {
            stroke: @red;
        }
	}

	.account-open & {
		.icon {
			stroke: @red;
		}

		.icon-arrow-small {
			transform: rotate(90deg);
		}
	}
}


.header-account {
	.translate-hide();
	.container;
	padding-top: @padding-mobile;
	padding-bottom: @padding-mobile;
	top: @menu-height;
	background-color: @white;
	left: auto;
	width: 100%;
	max-width: 420px;
	z-index: -1;

    .account-open & {
		.translate-show();
		position: fixed;
		box-shadow: 0 10px 45px -20px rgba(96, 96, 96, 0.20);
		z-index: 10;
	}

	.block-customer-login {
		.buttons {
			text-align: right;
			padding-bottom: @padding-mobile;
		}
	}

    .fieldset:last-child {
        margin-bottom: 0;
    }

	.buttons-inline {
		display: flex;
		align-items: center;
		line-height: 1.2;
		padding-bottom: @padding-mobile;
		color: @text-2;
		font-size: 1.4rem;

		.button, .button-dark {
			.button-small;
			margin-left: @padding-mobile;
		}
	}

	.links {
		> ul {
			display: flex;
			justify-content: space-around;
            align-items: center;

            li {
                margin-bottom: 0;
            }
		}
	}
}

.maxW(@ipad-v - 1, {
    .header-account {
        top: @mobile-header-height;
    }
});

.maxW(@tablet-max, {

	.header-account {
		.buttons-inline {
			flex-direction: column;
			margin-top: @padding-mobile;

			.button, .button-dark {
				margin: @padding-mobile auto 0;
				text-align: center;
			}
		}


		.account-open & {
			bottom: 0;
			overflow: auto;
		}
	}

});

.minW(@desktop-min, {

	.header-account {
		transform: translateY(20px);
		.transition(transform @transition-short, opacity @transition-short;);
		opacity: 0;
		max-height: 0;
		right: 90px;
		left: auto;
		top: -100%;
		overflow: hidden;
		border-top-left-radius: @radius;
		border-bottom-left-radius: @radius;
		border-bottom-right-radius: @radius;

		input {
			display: none;
		}

		.account-open & {
			transform: translateY(0);
			top: @menu-height;
			opacity: 1;
			max-height: 800px;

			input {
				display: inherit;
			}
		}

		.label {
			display: none;
		}

	}

	.skip-account {
		height: 100%;
		right: 60px;

		.menu-open & {
			.link-hidden
		}
	}

	.account-open {
		.header-search, .header-minicart {
			.link-hidden;
		}
	}
});

.minW(@desktop-sm, {
	.header-account {
		right: 150px;
	}

	.skip-account {
		right: 120px;

		.label {
			display: flex;
			width: 100px;
			color: @text-1;
		}
	}
});

.minW(@desktop-lg, {
	.header-account {
		right: 160px;
		.account-open & {
			top: @menu-height-lg;
		}
	}
});

.minW(@desktop-lg + 120px, {
	.skip-account {
		right: calc(~"@{minicart-width} - 4rem");

		.label {
			height: calc(~"@{menu-height-lg} - 1rem");
			max-height: @menu-height-lg;
		}
	}

	.header-account {
		right: @minicart-width;
	}
});
