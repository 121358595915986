input {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px @yellow inset;
    transition-delay: 9999s;
  }

  &[type='search'] {
    .form-input();
    font-weight: 600;
    .transition(background-color .2s);
    padding-right: 40px;
  }

  &[type='text'],
  &[type='password'],
  &[type='url'],
  &[type='search'],
  &[type='tel'],
  &[type='zip'],
  &[type='number'],
  &[type='date'],
  &[type='email'] {
    background-color: transparent;
    color: @text-1;
    outline: 0;
    border-color: @form-border;
    border-width: 0 0 1px;
    font-family: @font-1;
    padding: @padding-mini @padding-mobile;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: @text-1;
    }

    &.mage-error {
      &::placeholder {
        color: @error;
      }
    }
  }
}

input {
  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
}

textarea {
  resize: vertical;
  height: 4.2rem;
  width: 100%;

  &::placeholder {
    color: @text-1;
  }

  .minW(@desktop-min, {
    height: 3.8rem;
  });

  .minW(@desktop-lg, {
    height: 5rem;
  });
}

button {
  .form-reset();
}

input, textarea {
  &:disabled {
    background-color: @gray;
    border-color: @rgba-black;
    color: @text-3;
  }
}

.form-reset() {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0 none;
  outline: 0;
  font-family: inherit;
  box-shadow: none;

  &:active,
  &:hover,
  &:focus,
  &:visited {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
    background-color: transparent;
  }
}

.button-border(@color, @color-hover) {
  border: 2px solid @color;
  color: @color;
  .transition(border-color .2s, color .2s;);

  .icon {
    stroke: @color;
  }

  &:active,
  &:hover {
    border-color: @color-hover;
    color: @color-hover;

    .icon {
      stroke: @color-hover;
    }
  }
}

.button-solid(@color, @color-text) {
  border: 2px solid @color;
  background-color: @color;
  color: @color-text;
  .transition(border-color .2s, color .2s, background-color .2s;);

  .icon {
    stroke: @color-text;
  }

  &:active,
  &:hover,
  &:focus {
    border-color: @color;
    background-color: transparent;
    color: @text-1;

    .icon {
      stroke: @color;
    }

    &:visited {
      color: @text-1;
    }
  }

  &:visited {
    background-color: @color;
    border-color: @color;
    color: @color-text;
  }
}

.button-base() {
  .form-reset();
  border: 2px solid @text-2;
  color: @text-2;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  .transition(border-color .2s, color .2s;);
  -webkit-tap-highlight-color: transparent;

  &:active,
  &:hover,
  &:focus {
    border-color: @text-1;
    border-width: 2px;
    color: @text-1;
  }
}

.button {
  .button-base();
  padding: .8rem 2.6rem;
  border-radius: 40px;
  white-space: nowrap;
  display: inline-block;
  font-family: @font-1;
  font-size: 1.2rem;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .minW(@desktop-lg, {
    padding: 1.2rem 3rem;
    font-size: 1.4rem;
  });
}

.button-small {
  padding: .6rem 2rem;
  font-size: 1.2rem;

  .minW(@desktop-lg, {
    padding: .6rem 2rem;
    font-size: 1.2rem;
  });
}

.button-large() {
  padding: 1.5rem 4rem;
  font-size: 1.6rem;
}

.button-yellow {
  .button();
  .button-solid(@yellow, @text-1);

  &:focus {
    color: @yellow;
  }
}

.button-orange {
  .button();
  .button-solid(orange, @text-1);

  &:focus {
    color: orange;
  }
}

.button-red {
  .button();
  .button-solid(@red, @white);
}

.button-dark {
  .button();
  .button-solid(@text-1, @white);
}

.button-white {
  .button();
  .button-solid(@white, @text-1);
}

.form-row {
  margin: 2rem 0;
  transition: opacity @transition;
  position: relative;

  label {
    color: @text-1;
    font-family: @font-2;
    font-weight: 700;
  }

  input {
    width: 100%;
  }

  &.checkbox-styled {
    margin: .5rem 0;
  }

  .minW(@mobile-max, {
    display: flex;
    justify-content: stretch;
    align-items: center;

    .input-text {
      flex: 3;
    }

    label {
      flex: 1;
    }

  });
}

.form-label {

  .label-error & {
    color: @accent;
  }

  .is-dirty & {
    color: @text-1;
  }
}

.label-annotation {
  font-family: @font-1;
  font-weight: 300;
  color: @text-2;
  font-size: 10px;
  line-height: 1.2;
  text-align: right;
  flex-grow: 1;
}

.form-input, .input-text {
  border: 0;
  border-bottom: 1px solid @form-border;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  padding: .5rem @padding-mobile;
  min-height: 4.2rem;
  font-family: @font-1;
  line-height: 1.4;
  -webkit-appearance: none;

  .label-error & {
    border-color: @error;
  }

  &.parsley-error, .parsley-error & {
    border-color: @error;

    &::placeholder {
      color: @error;
    }
  }

  &.autosize {
    &:focus {
      box-shadow: none;
    }
  }

  .minW(@desktop-min, {
    min-height: 3.8rem;
  });

  .minW(@desktop-lg, {
    min-height: 5rem;
  });

}

.form-description {
  font-size: 1rem;
  line-height: 1.6;
}

.form-required {
  color: @error;
  font-weight: bold;
  font-style: normal;
}

.required {
  .form-required;
}

.required-mark {
  color: @error;
}

.parsley-errors-list {
  list-style: none;
  color: @error;
  font-size: 1.2rem;
  position: absolute;
  right: 0;
  bottom: -15px;
  line-height: 1.1;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
  }
}

.validation-advice {
  .parsley-errors-list;

  .onestepcheckout-index-index & {
    position: relative;
    bottom: -5px;
  }
}

.onestepcheckout-error {
  color: @error;
  font-size: 1.2rem;
  line-height: 1.1;
}

.checkbox-styled {
  padding-top: 1rem;
  padding-bottom: 3rem;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  ::selection {
    background: transparent;
    color: @text-1;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    width: 0;
    height: 0;
  }

  input[type='checkbox'] {
    &:checked {
      + label {
        &:before {
          .icon('check');
          background-position: center;
          background-size: 90% auto;
          border-color: @yellow-dark;
          background-color: @yellow-dark;
        }
      }
    }
  }

  input[type='radio'] {
    &:checked {
      + label {
        &:before {
          border-color: @yellow-dark;
          background-color: @yellow-dark;
          border-width: 2px;
          box-shadow: inset 0 0 0 3px #fff;
        }
      }
    }

    + label {
      &:before {
        border-radius: 50%;
        box-shadow: inset 0 0 0 3px #fff;
      }
    }
  }

  label {
    cursor: pointer;
    display: block;
    width: 100%;
    position: relative;
    padding-left: 3rem;
    text-align: left;
    min-height: 3rem;
    font-family: @font-1;
    font-weight: 400;
    color: @text-2;

    &:before {
      content: "";
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      border: 2px solid @yellow-dark;
      border-radius: 2px;
      background-color: @white;
      position: absolute;
      top: 0;
      left: 0;
    }

    a {
      text-decoration: underline;
      color: @text-1;
      transition: color @transition-short;

      &:hover {
        color: @red;
      }
    }
  }

  &.parsley-error,
  .parsley-error {
    label {
      color: @error;

      &:before {
        border-color: @error;
      }
    }
  }

  + .parsley-errors-list, .parsley-errors-list, .validation-advice {
    padding-left: 3rem;
    right: auto;
    bottom: 10px;
  }
}

.accept_field,
.checkout-agreements {
  .checkbox-styled;
  padding-left: 0;
}

.mage-error {
  &::placeholder {
    color: @error;
  }

  ~ label {
    color: @error;

    &:before {
      border-color: @error;
    }
  }
}

.validation-failed {
  border-color: @error;

  .accept_field & {
    label {
      color: @error;

      &::before {
        border-color: @error;
      }
    }
  }
}

.search-button {
  position: absolute;
  top: 1px;
  right: @padding-mini;
  bottom: 0;
  width: 40px;
  line-height: 0;
  border: 0;
  cursor: pointer;

  span {
    .screen-readers();
  }
}

// select {
// 	opacity: 0;
// }

.nice-select {
  border: 0;
  border-radius: 0;
  border-color: @yellow-dark;
  border-bottom: 1px solid @yellow-dark;
  width: 100%;
  color: @text-1;

  &:after {
    height: 10px;
    width: 10px;
    border-color: @text-1;
  }

  &:active,
  &.open,
  &:focus {
    border-color: @yellow-dark;
  }

  .current {
    white-space: normal;
    display: flex;
    line-height: 1;
    height: 100%;
    align-items: center;
  }

  .option {
    line-height: 1.2;
    display: flex;
    align-items: center;
    height: 20px; // IE 11 vertical align patch
    // white-space: pre-wrap;
  }

  .list,
  .filter-options-content {
    border-radius: 0;
    border: 0 none;
    width: 100%;
    box-shadow: 0 7px 20px -7px @category-item-shadow;

    .std & {
      padding: 0;
      margin: 4px 0 0 0;

      li {
        text-indent: 0;
        margin: 0;

        &:before {
          display: none;
          background: none;
        }
      }
    }
  }
}

.input-list {
  .list-reset;

  .input-box {
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.button-location {
  .button-dark;
}

.input-box {
  .form-row;

  .button-location {
    padding: .5rem @padding-mobile;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 1rem;
  }
}

.input-box-cols {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .input-box {
    width: calc(~"50% - @{padding-mobile}");
    margin-top: 0;
  }
}

.field {
  label {
    color: @text-1;
    font-family: @font-2;
    font-weight: 700;

    &.has-annotation {
      display: flex;
      flex-basis: 100%;
    }
  }

  .input-box {
    flex: 3;
  }

  input {
    .input-text();
  }
}


.form-columns-2 {
  .clearfix;
}

.select2-container {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.select2-container--default .select2-selection--single {
  border-bottom: 1px solid @yellow-dark;

  .parsley-error & {
    border-color: @error;
  }
}

.select2-dropdown {
  z-index: 3051;
  border-radius: 0;
  border: 0 none;
  width: 100%;
  box-shadow: 0 7px 20px -7px @category-item-shadow;
  color: @text-1;
}

.select2-container--default {
  .select2-selection--single {
    color: @text-1;
    outline: 0;
    min-height: 4.2rem;

    .select2-selection__placeholder {
      color: @text-1;
    }

    .select2-selection__rendered {
      min-height: 4.2rem;
      display: flex;
      align-items: center;
      width: 100%;
      outline: 0;
    }

    .select2-selection__arrow {
      top: 5px;

      b {
        border-bottom: 2px solid @text-1;
        border-right: 2px solid @text-1;
        display: block;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg);
        transition: transform @transition-short;
        height: 10px;
        width: 10px;
        border-color: @text-1;
      }
    }
  }

  .select2-results__option {
    outline: 0;
    color: @text-1;
    padding-left: 2rem;
    padding-right: 2rem;

    &[aria-selected=true] {
      background-color: @gray;
      font-weight: 700;
    }

    &--highlighted[aria-selected],
    &:hover {
      background-color: @gray;
      color: @text-1;
    }
  }

  .select2-search--dropdown {
    padding: @padding-mini;

    .select2-search__field {
      border: 1px solid @yellow-dark;
      min-height: 36px;
      padding-left: @padding-mobile;
    }
  }

  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transform: rotate(-135deg);
  }

}

#password-strength-meter-container {
  margin-top: 1rem;

  label {
    color: @text-3;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 900;
    font-family: @font-1;
    margin-bottom: @padding-mini;
    display: block;
  }

  .password-strength-wrapper {
    background-color: @gray;
    border-radius: @radius-sm;
    width: 140px;
    overflow: hidden;
  }

  .password-strength-meter {
    border-radius: @radius-sm;
    height: 8px;
  }
}

.password-strength-meter {

  &:before {
    .password-weak & {
      background-color: @error;
    }

    .password-medium & {
      background-color: @warning;
    }

    .password-very-strong &,
    .password-strong & {
      background-color: @success;
    }
  }
}

.field.choice {
  .checkbox-styled;
}

.actions-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .action {
    .button;
  }

  .primary {
    margin-right: 0 !important;
    margin-bottom: 0;

    .action {
      .button-dark;
    }
  }

  .secondary {
    margin-left: 1rem;

    a.action {
      margin-top: 0;
    }
  }
}

.contact-succes-message {
  text-align: center;
  color: @yellow-dark;
  margin-top: 5rem;
}
