.header-minicart {
    position: absolute;
    top: 0;
    right: -@padding-mobile;
    bottom: 0;
    width: @minicart-width;
	max-width: calc(~"100% + @{padding-mobile}*2");

	.minW(@mobile-max, {
		right: -@padding-tablet;
	});

	.minW(@desktop-sm, {
		border-left: 1px solid @border-light;

		.account-open & {
			border-left: 0 none;
		}
	});

	.minW(@desktop-lg, {
		right: -@padding-desktop;
		border-left: 1px solid @border-light;

		.minicart-open & {
			width: @minicart-width-lg;
		}
	});

    &:before {
        content: '';
        .translate-hide();
        bottom: 0;
        top: 0;
        background-color: @white;
        z-index: 1;
	}

	p {
		margin-top: @padding-mobile;
	}

    .minicart-open & {
        &:before {
			.translate-show();
			border-bottom: 1px solid @border-light;
        }
	}

	.menu-open & {
		border-left: 0;
	}

    .skip-link {
        z-index: 5;
    }

    .skip-content {
        .translate-hide();
        overflow-y: auto;
        top: 0;
        height: 100vh;
        padding-top: @menu-height;

        .minicart-open & {
			transform: none;
        }

        .minW(@mobile-max, {
            left: auto;
            width: 500px;
            max-width: 100%;
        }
        );

        .minW(@desktop-min, {
            top: 0;
		});

		.minW(@desktop-lg, {
            padding-top: @menu-height-lg;
        });
    }
}

.skip-cart {
    .align-v();
    right: @padding-mobile;
	color: @text-1;
	height: 24px;

	.minW(@mobile-max, {
		right: @padding-tablet;
	});

    .label {
        .align-v();
        right: 50px;
        font-weight: 900;
        font-size: 1.4rem;
        padding: 1rem;
        display: none;

        .minicart-open & {
            display: block;
		}

		.minW(@desktop-sm, {
			display: block;
		});
    }

    .icon-cart {
        .minicart-open & {
            stroke: @yellow-dark;
		}
    }

    .menu-open & {
        display: none;
	}
}

.generate-delay(@n, @i: 1) when (@i =< @n) {
	&:nth-child(@{i}) {
		animation-delay: e(%("%dms", @i*150));
	}
	.generate-delay(@n, (@i + 1));
}

.minicart-open {
	.overlay {
		 .overlay-visible;
		 width: calc(~"100vw - @{minicart-width}");
		 right: auto;

		.minW(@desktop-lg, {
			width: calc(~"100vw - @{minicart-width-lg}");
		});
	}

    .minicart-order {
		width: @minicart-width;

		.minW(@desktop-lg, {
			width: @minicart-width-lg;
		});
	}
}

@keyframes animate-menu-in {
	0% {
		opacity: 0;
		transform: translateX(10px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
