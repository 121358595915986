.checkout-success {
    .content;

    .actions-toolbar {
        margin-top: @padding-tablet;
    }
}

.checkout-onepage-success {
    .action.primary,
    .action-primary {
        .button-dark;
        margin-top: @padding-mobile;
    }

    .action {
        &.print {
            .button;
            .button-small;
            margin-right: @padding-mobile;
        }
    }
}
