.search.results {
    .toolbar-products + dl.block {
        display: none;
    }
}

.maxW(@mobile-max, {
    .search.results {
        .toolbar-products {
            &:first-of-type {
                flex-direction: row;
                margin-bottom: 0;
            }
        }
    }
});
