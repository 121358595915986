.page-wrapper,
.section,
.columns {
    position: relative;
}

.container {
    padding-right: @padding-mobile;
    padding-left: @padding-mobile;
    margin: 0 auto;

    .minW(@mobile-max,  {
        padding-right: @padding-tablet;
        padding-left: @padding-tablet;
    });

    .minW(@desktop-lg, {
        padding-right: @padding-desktop;
        padding-left: @padding-desktop;
    });
}

.page-layout-1column {
	.columns {
		max-width: @desktop-min + 60px;
		margin: auto;
    }
}

.cms-index-index.cms-home {
    .columns {
		max-width: @desktop-max;
		margin: auto;
	}
}

.page-layout-1column,
.page-layout-3columns,
.page-layout-2columns-left,
.page-layout-2columns-right {
    .sidebar {
        padding-top: @padding-tablet;
        .container;
    }

    .column.main {
        .container;
        padding-top: @padding-tablet;
        width: 100%;

        .maxW(@ipad-v - 1, {
            padding-top: @padding-mobile + @padding-mini;
        });
    }
}

.page-main {
    padding: 0;
    max-width: 100%;
}

.columns-2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

    &:after {
        content: none;
    }

	.col {
		width: 100%;
		margin-bottom: @padding-tablet;
	}
}


.maxW(@desktop-min, {
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .sidebar {
            display: none;
        }

        .column.main {
            width: 100%;
        }
    }
});

.maxW(@desktop-sm - 1px, {
	.page-layout-3columns {
        .sidebar-main {
			display: none;
		}
	}
});

.minW(@desktop-min, {

	.sidebar-additional {
		padding-left: @padding-mobile + @padding-mini;
	}

    .page-layout-2columns-left {
        .sidebar {
			width: @sidebar-w;
			float: left;
			clear: left;
		}

		.column.main {
			float: right;
			width: calc(~"100% - @{sidebar-w}");
			padding-left: 0;
		}
    }

    .page-layout-2columns-right {
		.sidebar {
			width: @sidebar-w;
			float: right;
			clear: right;
		}

		.column.main {
			float: left;
			width: calc(~"100% - @{sidebar-w}");
			padding-right: 0;
		}
	}

	.page-layout-3columns {

		.sidebar-main {
			width: @sidebar-w;
			float: left;
			clear: left;
		}

		.sidebar-additional {
			width: @sidebar-w;
			float: right;
			clear: right;
		}

		.column.main {
			width: calc(~"100% - @{sidebar-w}");
			padding-right: 0;
		}
	}
});

.minMax(@tablet-md, @tablet-max, {
	.columns-2 {
		justify-content: space-between;

		.col {
			width: calc(~"50% - @{padding-mobile}");
		}
	}
});

.minW(@desktop-sm, {
	.page-layout-3columns {
		.sidebar-main {
			width: @sidebar-w-lg;
			float: left;
		}

		.column.main {
			width: calc(~"100% - @{sidebar-w} - @{sidebar-w-lg}");
			padding-left: 0;
		}

		.sidebar-additional {
			width: @sidebar-w;
			float: right;
			clear: right;
		}
	}
});

.minW(@desktop-lg, {

	.sidebar-additional {
		padding-left: @padding-tablet;
	}

	.page-layout-2columns-right {
		.sidebar {
			width: @sidebar-w-lg;
		}

		.column.main {
			width: calc(~"100% - @{sidebar-w-lg}");
		}
	}

	.page-layout-3columns {
		.sidebar-main {
			width: @sidebar-left-3col;
		}

		.column.main {
			width: calc(~"100% - @{sidebar-left-3col} - @{sidebar-right-3col}");
		}

		.sidebar-additional {
			width: @sidebar-right-3col;
		}
	}

	.columns-2 {
		.col {
			width: calc(~"50% - @{padding-tablet}");
		}
	}

});

.minW(@desktop-max, {
	.page-wrapper, .page-header .container {
		max-width: @desktop-max;
		margin: auto;
	}

	.page-header .container {
		position: relative;
	}

	.skip-links .header-minicart {
		position: fixed;
		bottom: auto;
	}

	.skip-links .header-minicart .skip-cart{
		position: fixed;
		transform: none;
		top: 2.5rem;
	}

	.skip-links .header-minicart .skip-content {
		position: fixed;
	}

});

.sidebar {
    #layered-filter-block,
    .filters {
        display: none;
    }
}
