@modal-width: 480px;
@modal-height: 240px;
@modal-width-lg: 560px;
@modal-height-lg: 260px;

@modal-extras-width: 540px;
@modal-extras-height: 585px;
@modal-extras-width-lg: 640px;
@modal-extras-height-lg: 620px;

.add-to-cart-modal {
    position: fixed;
    padding: 0;
    margin: auto;
	width: @modal-width;
    height: @modal-height;
    max-width: calc(~"100% - @{padding-mobile} * 2");
    max-height: calc(~"100vh - @{padding-mobile} * 2");
    background-color: transparent;
    box-shadow: @box-shadow-message;
    border-radius: @radius;

    .modal-inner-wrap {
        position: relative;
        margin: 0;
        padding: @padding-mobile + @padding-mini;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        overflow: auto;
        background-color: @white;
    }

    .modal-header {
        padding: 0 0 @padding-mobile;
    }

    .modal-title {
        font-size: 1.4rem;
        font-family: @font-1;
        border-bottom: 0;
    }

    .modal-content {
        padding: 0;
    }

    .modal-extras {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: @padding-mobile;
    }

    .modal-footer {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
        border: 0;
        padding: @padding-mobile 0 0;

        .primary {
            margin-left: @padding-mobile;
        }

		.checkout-button {
			margin-left: @padding-mobile;
			.button {
				.button-dark;
			}
		}

		.register-button {
			margin-left: @padding-mobile;
			.button {
				.button-red;
			}
		}
    }

	.product-name,
    .product-item-name {
		.h6;
		font-size: 1.4rem;
	}

	.product-content {
		display: flex;
		padding-bottom: @padding-mobile;
		border-bottom: 1px solid @rgba-black;
		font-size: 1.4rem;
	}

	.product-img {
		.thumbnail;
		width: 100px;
		height: 80px;
		margin: 0 @padding-mobile 0 0;
		padding: @padding-mini;
		pointer-events: none;

		img {
			max-height: 100%;
		}
    }

    .product-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

	&.related-product {
        width: @modal-extras-width;
        height: @modal-extras-height;

        .maxH(@mobile-max, {
            height: auto;
        });

        .modal-inner-wrap {
            height: auto;
        }

		.modal-footer {
			flex-direction: column;
            align-items: center;
            justify-content: center;
		}
	}

	.related-product-name,
	.related-product-price {
		color: @text-1;
		font-weight: 700;
	}

	.related-product-price {
		margin-top: .5rem;
		margin-bottom: 1rem;
		font-size: 2.2rem;
		position: relative;
		display: inline-block;

		&:before {
			content: '';
			background-color: @yellow;
			position: absolute;
			left: 14px;
			right: 34px;
			height: 16px;
			z-index: -1;
			bottom: 2px;
		}
    }

	.related-product-image {
        min-height: 130px;
		max-height: 130px;
        margin: @padding-tablet;

        .maxH(615px, {
            margin: 1rem;
        });
	}
}

.maxW(@mobile-md, {
	.add-to-cart-modal {
        height: 285px;

        &.related-product {
            height: auto;
        }

		.modal-footer {
			flex-direction: column;
			text-align: center;

			.button {
				display: block;
				width: 100%;
				max-width: 200px;
				margin-left: auto;
				margin-right: auto;
            }

            .primary {
                margin-top: @padding-mobile;
            }

			.continue {
				margin-bottom: @padding-mobile;
			}

			.checkout-button {
				margin-left: 0;
			}

			.checkout-button {
				margin-left: 0;
			}
		}
	}
});

.maxW(@modal-extras-width, {
    .add-to-cart-modal {
        &.related-product {
            height: auto;
        }
    }
});

.maxW(@ipad-v, {
    .add-to-cart-modal {
        .modal-title {
            font-weight: 500;
        }
        .modal-inner-wrap {
            background-color: @white;
        }
    }
});

.minW(@desktop-lg, {
	.add-to-cart-modal {
        width: @modal-width-lg;
        height: @modal-height-lg;

        &.related-product {
            width: @modal-extras-width-lg;
            height: @modal-extras-height-lg;
        }

        .modal-title {
            font-size: 1.6rem;
        }

        .modal-footer {
            padding-top: @padding-mobile + @padding-mini;
        }
	}
});
