.modal-content,
.modal-footer {
    .action {
        .button();
    }

    .action-primary {
        margin-left: @padding-mobile;
        .button-dark();
    }

    .action-secondary {
        .button();
    }

    .action + .action {
        margin-left: @padding-mobile;
    }

    .select {
        .nice-select;
    }

    .label {
        font-family: @font-2;
        color: @text-1;
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.modal-opened, .modal-open {
	.overlay {
		.overlay-visible;
	}

	.mfp-bg {
		display: none;
	}
}

.modal-open {
	.overlay {
		background-color: @yellow;
    }

    .modals-overlay {
        display: none;
    }
}
