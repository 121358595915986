.header-search {
	top: @menu-height;
	z-index: 1;
	.input-box {
		margin: 0;
		background-color: @main-bg;

		label {
			.screen-readers();
		}
	}

	.search-button {
		background-color: @text-1;
		width: 33px;
		padding: 0;
		border-radius: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.minW(@desktop-min, {
			border-radius: 50%;
		});

		.icon {
			height: 14px;
			min-width: 14px;
			stroke: @white;
		}
	}

	&.search-hidden {
		max-width: 36px;
		overflow: hidden;

		.input-text {
			border: 0;
		}
	}
}

.skip-search {
    display: none;

    .menu-open & {
        display: none;
    }
}

.maxW(@tablet-max, {
	.header-search {
		height: @search-height;
		.translate-hide();

		.menu-open & {
			.translate-show();
		}

		input {
			&:focus {
				background-color: @main-bg;
			}
		}

		.search-button {
			right: 0;
			width: 44px;
		}
	}

});

.minW(@mobile-max, {

	.header-search {

		input {
			padding-left: @padding-tablet;
			padding-right: @padding-tablet;
		}
	}

});

.minW(@desktop-min, {
	.header-search {
		position: absolute;
		top: 8px;
		left: 785px;
		max-width: 155px;
		background-color: transparent;

		.search-button {
			left: -1px;
			bottom: 0;
		}

		.input-box {
			padding-left: 2rem;
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
		}

		.input-text {
			padding-left: @padding-mobile;
			padding-right: @padding-mobile;
			text-indent: 1rem;
			padding-top: .5rem;
			padding-bottom: .5rem;
			border-bottom: 1px solid @yellow;
			min-height: 34px;
			height: 34px;
		}

		.menu-open & {
			transform: none;
			.link-hidden
		}
	}
});

.minW(@desktop-md, {
	.header-search {
		left: auto;
        right: @sidebar-w-lg;
		width: 160px;
		max-width: 160px;
	}
});

.minW(@desktop-md-2, {
	.header-search {
		left: 900px;
		width: 200px;
		max-width: 200px;
	}
});

.minW(@desktop-lg, {
	.header-search {
		top: 19px;
		left: 990px;
		max-width: 370px;

		.input-text {
			height: 42px;
			text-indent: 2rem;
		}
		.search-button {
			width: 42px;
		}
	}
});

.minMax(@desktop-min, @desktop-md, {
	.header-search {
		max-width: 160px;
		width: 160px;
		transition: max-width @transition;
		z-index: 50;
		background-color: @white;

		.minicart-open & {
			z-index: 0;
		}
	}
});

.minW(@desktop-lg+100px, {
	.header-search {
		width: 370px;
		max-width: 370px;
	}
});
