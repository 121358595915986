@import '../../variables';

.account {

    .invoices {
        .content;

        &__head {
            display: flex;

            > h5 {
                width: 25%;
                margin-bottom: 8px;
            }
        }

        &__table {
            display: flex;
            flex-direction: column;

        }

        .invoice {
            display: flex;
            align-items: center;
            padding: 8px 0;
            border-top: 1px solid @text-4;

            @media all and (max-width: @ipad-v) {
                flex-direction: column;
                align-items: center;
            }

            &:first-child {
                margin-top: 8px;
            }

            &--unpaid {
                font-weight: 600;
                color: @red;

                .tooltip,
                .button {
                    color: @red;
                    border-color: @red;
                }

                .tooltip {
                    @media all and (max-width: @ipad-v) {
                        flex: 1 0 auto;
                    }
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                > a {

                    &:not(:first-child) {
                        margin-top: 12px;

                        @media all and (max-width: @ipad-v) {
                            margin-top: 0;
                            margin-left: 10px;
                        }

                        @media all and (max-width: @mobile-md) {
                            margin-top: 6px;
                            margin-left: 0;
                        }
                    }
                }
            }

            &--corrected {
                color: @yellow-dark;
                border-color: @yellow-dark;
            }

            > p {
                display: flex;
                width: 25%;
                margin: 0;

                @media all and (max-width: @ipad-v) {
                    width: auto;
                }

                &.invoice {

                    &__buttons {

                        @media all and (max-width: @ipad-v) {
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 10px;
                        }

                        @media all and (max-width: @mobile-md) {
                            flex-direction: column;
                        }
                        a {
                            text-align: center;
                            @media all and (min-width: @ipad-v) {
                                min-width: 170px;
                            }
                            @media all and (min-width: @tablet-md) {
                                min-width: 200px;
                            }
                        }
                    }

                    &__unpaid {
                        width: 5%;

                        @media all and (max-width: @ipad-v) {
                            width: auto;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            &-container {

                .credit-toggler {
                    text-align: right;
                    span {
                        cursor: pointer;
                        color: @red;
                    }
                }
                .cm-invoices {
                    display: none;
                    .invoice {
                        &:first-child {
                            border: none;
                        }
                    }
                }
            }
        }

        .show-tablet {
            display: none;

            @media all and (max-width: @ipad-v) {
                display: inherit;
            }
        }

        .hide-tablet {

            @media all and (max-width: @ipad-v) {
                display: none;
            }
        }
    }
}
