.cms-no-route {
	.page-wrapper {
		min-height: calc(~"100vh - @{menu-height}");
		display: flex;
		flex-direction: column;

		.minW(@desktop-lg, {
			min-height: calc(~"100vh - @{menu-height-lg}");
        });
	}

	.block-subscribe {
		display: none;
	}

	.page-footer {
		background: none;
	}

	.column.main {
		.container;
		width: 100%;
		flex-grow: 1;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 400px;
		font-family: @font-2;
		line-height: 1.2;
		text-align: center;
		font-size: 2.2rem;
		background-image: url('../images/newsletter.png');
		background-size: auto 500px;
		background-repeat: no-repeat;
		background-position: center;
		color: @footer-links;

		.std, a {
			color: @yellow-dark;
		}

		b, strong {
			font-weight: 700;
			font-size: 105%;
		}

		.minW(@tablet-md, {
			font-size: 4rem;
		});

		.minW(@desktop-min, {
			font-size: 5.6rem;
		});
	}
}
