.section-slider-main {
	position: relative;
	min-height: @search-min-h-lg;
	background-image: linear-gradient(@slider-gradient-top, @slider-gradient-bottom);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: @text-1;
	text-align: center;
    padding-top: @menu-height;

    .maxW(@ipad-v - 1, {
        padding-top: @mobile-header-height;
    });

	.minW(@desktop-min, {
		height: calc(~"100vh - @{slider-cat-h}");
		text-align: left;
		padding-right: @main-search-width;
		max-height: 600px;
		height: calc(~"100vh - @{slider-cat-h}");
		min-height: @search-min-h-lg;

		.owl-stage-outer,
		.owl-item {
			height: 100%;
		}

		.owl-stage {
			height: 100%;
			display: flex;
			align-items: center;
		}
	});

	.minW(@desktop-lg, {
		justify-content: center;
		padding-top: @menu-height-lg;

		padding-right: @main-search-width-lg;
		padding-left: 0;
		height: calc(~"100vh - @{slider-cat-h-lg}");
	});
}

.slider-main {
	.minW(@desktop-min, {
		height: 100%;
	});
}

.slider-main__content {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	margin-bottom: @padding-tablet;

	.slider-main__text,
	.slider-main__image,
	.slider-main__text--additional {
		z-index: 1;
	}

	.maxW(@desktop-min - 1px, {
		.slider-main__image {
			order: 0;
		}
		.slider-main__text {
			order: 1;
		}
		.slider-main__buttons {
			order: 2;
		}
		.slider-main__text--additional {
			order: 3;
		}

		.slider-bg {
			display: none;
		}
	});

	.minW(@desktop-min, {
		margin-bottom: 0;
		height: 100%;

		.slider-bg {
			position: absolute;
			right: 0;
			top: 0;
			left: 0;
			bottom: 0;
			opacity: .35;
			filter: blur(10px);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			z-index: 0;
		}
	});
}

.slider-main__text {
	width: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
	font-family: @font-banner;
	font-size: 2.2rem;
	font-weight: 300;
	line-height: 1.2;

	.active & {
		animation-name: slider-fade-in-side;
		animation-duration: .6s;
		animation-timing-function: ease-in;
		animation-iteration-count: 1;
	}

	strong {
		text-shadow: 1px 1px;
	}

	.maxW(@desktop-min - 1px, {
		padding-left: @padding-mobile;
		padding-right: @padding-mobile;
	});

	.minW(@mobile-md, {
		font-size: 3.2rem;
	});

	.minW(@mobile-max, {
		font-size: 4.6rem;
	});

	.minW(@desktop-min, {
		font-size: 3.6vw;
		width: 50%;
		height: 70%;
		display: flex;
		flex-direction: column;
		padding-left: @padding-tablet;
		margin-bottom: auto;
		margin-top: auto;
	});

	.minW(@desktop-lg, {
		width: 45%;
		font-size: 6rem;
		padding-left: @padding-lg;
	});
}

.slider-main__price {
	.text-red;
	font-size: 140%;
	line-height: 1;
}

.slider-main__text--additional {
	width: 100%;
	margin-top: @padding-mobile;
	text-align: center;
	font-family: @font-1;
	color: @text-1;
	line-height: 1.4;
	padding-left: @padding-mobile;
	padding-right: @padding-mobile;

	.active & {
		animation-name: slider-fade-in;
		animation-duration: .5s;
		animation-timing-function: ease-in;
		animation-iteration-count: 1;
	}

	.minW(@ipad-v, {
		font-size: 1.6rem;
	});

	.minW(@desktop-min, {
		position: absolute;
		bottom: 5rem;
	});
}

.slider-main__image {
	height: 220px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: @padding-mobile;
	padding-bottom: @padding-mobile;
	padding-left: @padding-mobile;

	> a {
		height: 100%;
		display: flex;
    	align-items: center;
	}

	img {
		max-height: 100%;
	}

	.active & {
		animation-name: slider-fade-in;
		animation-duration: .5s;
		animation-timing-function: ease-in;
		animation-iteration-count: 1;
	}

	.minW(@desktop-min, {
		padding: 0;
		height: 100%;
		max-height: 100%;
		width: 50%;
	});

	.minW(@desktop-lg, {
		width: 55%;
	});

	.maxW(@tablet-max, {
		> a {
			height: 100%;
		}
	});
}

.slider-main__button {
	.button-red;
	margin-top: @padding-mobile;
}

.slider-main__nav {
	.container;
	display: flex;
	justify-content: center;
	padding-bottom: @padding-mobile;
	z-index: 10;

	&.disabled {
		display: none;
	}

	.minW(@desktop-min, {
		justify-content: flex-start;
		position: absolute;
		bottom: 0;
		left: 0;
	});

	.icon {
		height: 20px;
		cursor: pointer;
	}

	.icon-arrow-small {
		transition: stroke @transition;
		stroke: @fill-light;

		&:hover {
			stroke: @red;
		}
	}

	.disabled {
		opacity: .3;
		pointer-events: none;
	}

	.owl-next {
		margin-left: 30px;

		.icon {
			transform: rotate(180deg);
		}
	}

    .owl-next,
    .owl-prev {
        &:hover,
        &:active,
        &:focus {
            border: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }
}

@keyframes slider-fade-in-side {
    0% {
		transform: translateX(-50px);
		opacity: 0;
    }
    100% {
		transform: translateX(0);
		opacity: 1;
    }
}


@keyframes slider-fade-in {
    0% {
		opacity: 0;
    }
    100% {
		opacity: 1;
    }
}
