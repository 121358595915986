.message.global {
    &.cookie,
    &.noscript {
        background: @yellow-dark;
        color: @white;
        font-size: .8em;
        z-index: 11;

        .content {
            background-color: transparent;
            padding: 0;
            margin: 0 auto;
            max-width: 1200px;
            text-align: center;
            line-height: 1.6;

            a {
                .content-link();
                color: @white;

                &:hover {
                    color: @text-1;
                }
            }
        }

        .actions {
            .action {
                .button-dark;
            }

            .maxW(@tablet-max, {
                text-align: center;
            });
        }
    }
}

.minW(@ipad-v, {
    .message.global {
        &.cookie,
        &.noscript {
            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: @padding-tablet;
                padding-right: @padding-tablet;
            }

            .actions {
                margin-top: 0;
                margin-left: @padding-tablet;
            }
        }
    }
});
