@import './product-list/filters';
@import './product-list/item';

.category-description {
	padding-bottom: @padding-tablet;
	max-width: 800px;

	a {
		text-decoration: underline;
	}
}

.page-products {
    .page-title-wrapper {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;

        .category-special-offer {
            margin-left: @padding-mobile;

            a {
                display: block;
            }
        }

        .maxW(@desktop-min, {
            flex-wrap: wrap;

            .category-special-offer {
                margin: @padding-mobile auto;
            }
        });
    }
}

.product-items {
	.list-reset;
	padding-top: @padding-mobile;

	.item, .product-item {
        margin: @padding-tablet auto;
    }
}

.product-item-details {
    .product-availability {
        font-size: 1.4rem;
        color: @success;

        .icon {
            stroke: @success;
        }

        &.product-out-of-stock {
            color: @error;

            .icon {
                stroke: @error;
            }
        }
    }
}

.page-products .toolbar .limiter {
    display: block;
}

.toolbar-products {
    text-align: left;
    padding: 0;
}

.products-grid {
    padding-top: @padding-lg;
    padding-bottom: @padding-lg;

    + .toolbar-sorter,
    + .toolbar-sorter + .toolbar-products .limiter {
        display: none;
    }

    .label {
        .attribute-label();
    }
    .value {
        .attribute-value();
    }
}

.minW(@mobile-max, {
    .columns .products-grid .product-items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -@padding-mobile;
        margin-right: -@padding-mobile;
        min-width: 100%;

		.item, .product-item {
			margin: @padding-mobile;
			width: calc(~"50% - @{padding-mobile} * 2");
		}
    }
});

.minW(@tablet-md, {
    .columns .products-grid .product-items {
		.item, .product-item {
			margin: @padding-mobile;
			width: calc(~"33.33% - @{padding-mobile} * 2");
		}
	}
});

.minW(@desktop-min, {
    .columns .products-grid .product-items {
		.item, .product-item {
			margin: @padding-mobile;
			width: calc(~"50% - @{padding-mobile} * 2");
		}
	}
});

.minW(1150px, {
    .columns .products-grid .product-items {
		.item, .product-item {
			margin: @padding-mobile;
			width: calc(~"33.33% - @{padding-mobile} * 2");
		}
	}
});

.minW(1500px, {
    .columns .products-grid .product-items {
		.item, .product-item {
			margin: @padding-mobile;
			width: calc(~"25% - @{padding-mobile} * 2");
		}
	}
});

//.minW(1750px, {
//    .columns .products-grid .product-items {
//		.item, .product-item {
//			margin: @padding-mobile;
//			width: calc(~"20% - @{padding-mobile} * 2");
//		}
//	}
//});

.maxW(@mobile-max, {
    .products-grid,
    .product-items {
        margin: 0;
        padding: 0;

        > .item:first-of-type {
            margin-top: 0;
        }
    }
});
