.icon {
    max-height: 30px;
    max-width: 30px;
    height: auto;
    // width: auto;
    stroke: @icon-light;
    transition: stroke @transition;

	.minW(@desktop-lg, {
		max-height: 38px;
		max-width: 38px;
	});
}

svg {
    path {
        stroke: inherit;
    }
}

.icon-large {
    max-height: 100%;
    max-width: 100%;
}

.icon-arrow {
    height: 7px;
}

.icon-cart {
	height: 21px;
	max-width: 39px;
}

.icon-user {
    height: 25px;
    width: 20px;
}

.icon-arrow-small {
    .icon;
    height: 13px;
    width: 13px;
}

.icon-search {
    .icon;
    height: 19px;
    width: 20px;
    stroke: @text-1;

    &:hover {
        stroke: @red;
    }
}

.icon-items {
    .icon-large();
    height: 65px;
}

.icon-delivery {
    .icon-large();
    height: 50px;
    fill: @text-1;
}

.icon-delivery-sm {
    .icon;
    height: 10px;
    width: 16px;
}

.icon-safety {
    .icon-large();
    height: 75px;
}

.icon-advice {
    .icon-large();
    height: 70px;
}

.icon-close {
    width: 10px;
    height: 10px;
    stroke: @red;
}

.icon-promotion {
	width: 72px;
	height: 72px;
	border-radius: 50%;
    background-color: @icon-red;
    .icon('design-scissor');
	background-size: 35px auto;
	background-position: center;
}
