body.customer-account-create {
  .form-create-account {
    .tt-contractor {
      display: none;
    }
    .fieldset {
      &.inactive {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          z-index: 10;
          width: 100%;
          top: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.5);
        }
      }
      .login-as-customer {
        display: none;
      }
    }
  }
}
