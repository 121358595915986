#checkout {
  .opc-block-summary {
    .table-totals {
      .totals {
        &.shipping {
          display: none;
        }
      }

      .totals-tax {
        display: none;
      }
    }
  }
}

.checkout-payment-method {
  .payment-method {
    & + .payment-method {
      .payment-method-title {
        border: none;
      }
    }
    .field {
      &.choice {
        label {
          &:before {
            top: 4px;
          }
        }
      }
    }
  }
}