@image-size: 7rem;

.checkout-cart-index {
    .page-main .columns {
        max-width: @desktop-sm;
    }
}

.table-checkout-shipping-method,
.table-checkout-shipping-method tbody td,
.table-checkout-shipping-method tbody tr{
    border: none;
}

.cart-container {
    .form-cart {

        .product-image-wrapper {
            width: @image-size;
            height: @image-size;
            padding: 3px;
            border: solid 1px @gray;
            float: left;
        }

        .product-item-photo {
            height: auto;
            max-width: @image-size;
        }

        .product-item-name {
            font-size: 1.6rem;
            font-weight: 900;
            padding-top: @padding-mini;
            padding-right: @padding-mobile;
        }

        .product-image-container {
            max-width: @image-size;
        }

        .product-item-details {
            padding-left: @image-size + @padding-mobile;

            .minW(@ipad-v, {
                padding-left: @padding-mobile;
            });
        }

        .action.continue {
            .button;

            span {
                line-height: 1.3;
            }
        }

        .action.clear {
            color: red;
            &:before {
                font-size: 32px;
                line-height: 16px;
                color: inherit;
                content: '\e604';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }

        .action.primary, .action-primary {
            .button-dark;
        }

        .action.secondary,
        .action-secondary,
        .action.continue,
        .action.action-delete,
        .action.action-edit {
            .button;
            .button-small;
        }

        .action.update, .action.clear {
            &:hover,
            &:focus,
            &:active {
                border: 0;
            }
        }

    }

    td.item {
        border: 0;
    }

    th {
        border-bottom: 0;
    }

    .shipping {
        padding-top: 0;
        .block-title {
            strong {
                .h3;
            }
        }
    }

    #block-shipping {
        display: none;
    }

    .cart {
        .pager {
            .toolbar-amount {
                display: none;
            }
        }
    }
}

#block-summary {
    padding: 0;
}

.cart-summary {
    background-color: transparent;
    padding: 0;

    &._sticky {
        position: unset !important;
    }

    .summary.title {
        display: none;
    }

    .block {
        &:first-of-type {
            margin-top: 0;
        }
        .title {
            border: 0;
        }

        &.discount {
            margin-top: @padding-tablet;

            .title {
                color: @text-2;
            }
        }
    }

    .action.primary.checkout {
        .button-red;
        .button-large();
    }

    .actions-toolbar {
        .primary .action {
            .button;
        }
    }

    .title {
        font-family: @font-2;
        color: @text-1;
    }

    .data.table.totals {
        font-family: @font-2;
        border: 0;
        width: 100%;

        th, td, tr {
            border: 0;
            background-color: @white;
        }

        th {
            color: @text-2;
            line-height: 1.2;
        }

        td {
            color: @text-1;
            font-weight: 900;
        }
    }

    .cart-totals {
        border: 0;

        .grand.totals.excl {
            display: none;
        }
    }

    .data.table {
        font-size: 1.6rem;
        line-height: 1.2;

        strong {
            font-weight: 900;
        }

        .price {
            font-size: 2rem;
            font-weight: 900;
        }

        .grand.totals {
            &.incl {
                border-top: 1px solid @rgba-black;
                margin-top: @padding-mobile;
            }

            th, td {
                font-weight: 700;
                vertical-align: bottom;
                padding-top: @padding-mobile;
            }

            .price {
                font-size: 3.2rem;
            }
        }

        .totals-tax {
            display: none;
            th, td {
                padding-bottom: @padding-mobile;
            }
        }
    }

    .discount {
        .coupon {
            margin-bottom: 0;
        }
    }
}

.cart-import {
    display: flex;
    gap: 50px;
    .maxW(@mobile-max, {
        flex-direction: column;
        gap: 20px;
    });
    form {
        margin-top: 15px;
        display: flex;
        align-items: center;
    }
    .how-to-help {
        cursor: pointer;
        text-decoration: underline;
        font-size: 0.9em;
    }
    .instruction {
        display: none;
    }
}

.totals {
    &.shipping {
        display: none;
    }
}

#shopping-cart-table {
    border: 0;
    font-family: @font-2;
    color: @text-1;

    .item-actions {
        td {
            padding: 0;
        }
    }

    thead {
        display: none;
    }

    tr,
    td,
    .item {
        border: 0;

        &:not(.message) {
            padding: @padding-mobile 0;
        }
    }

    .qty {
        padding-left: @padding-mini;
        padding-right: @padding-mini;
    }

    .item-actions {
        border-bottom: 1px solid @rgba-black;
    }

    .maxW(@mobile-max, {
        .actions-toolbar {
            justify-content: center;
        }
    });

    .maxW(@ipad-v - 1, {
        .item-info .col {
            &:not(.item) {
                border-top: @padding-mobile solid transparent;
            }
        }
    });
}

.minW(@mobile-max, {
    .cart-totals {
        .table-wrapper {
            margin-top: 0;
            font-size: 1.2rem;
        }
    }
});

.maxW(@mobile-max, {
    .cart.table-wrapper .item-info {
        .col:before {
            white-space: normal;
            font-weight: 500;
        }
    }
});

.maxW(@tablet-max, {
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .form-cart,
    .cart-container .cart-gift-item,
    .cart-summary {
        width: 100%;
        float: none;
    }
    .cart-container {
        display: flex;
        flex-direction: column-reverse;
    }
});

.minW(@tablet-max + 1, {
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .form-cart,
    .cart-container .cart-gift-item,
    .cart-summary {
        width: calc(~"50% - @{padding-mobile}");
    }
});
