.cms-kontakt {
	.std {
		.input-message {
			align-items: baseline;

			textarea {
				min-height: 100px;
			}
		}
	}
}

.contact-list {
	padding-left: 0;

	li {
		margin-bottom: @padding-desktop;
	}
}


.contact-item {
	margin-bottom: @padding-tablet;

    strong {
        color: @text-1;
    }
}

.map {
	position: relative;

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		max-height: 100%;
		max-width: 100%;
		height: 100%;
		width: 100%;
	}
}

.contact-form {
	.clearfix;
	margin-bottom: @padding-tablet;

	.input-box {
		label {
			white-space: nowrap;
			line-height: 1;
		}
	}

	.button {
		float: right;
		margin-top: 1rem;
	}
}

.map-container {
	position: relative;
	height: 100%;
}

.headquarters-map {
	height: 300px;
	margin-bottom: @padding-tablet;
}

.select-distribution {
	float: none;
	margin-bottom: @padding-tablet;
}


.distributors-map {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		max-height: 100%;
		max-width: 100%;
		height: 100%;
		width: 100%;
	}
}

.distribution-list {
	li {
		display: none;
	}

	.active {
		display: block;
	}
}

.marker-list {
	font-family: @font-2;
	font-weight: 700;

	li {
		position: absolute;
		cursor: pointer;
		transition: color @transition-short;

		&:after {
			content: '';
			display: block;
			height: 14px;
			width: 14px;
			border: 4px solid @gray;
			border-radius: 50%;
			.align-h();
			top: -12px;
			transition: border-color @transition-short, background-color @transition-short;
		}

		&:hover {
			color: @text-1;

			&:after {
				border-color: @text-1;
			}
		}

		&:nth-child(1) { // Chełm
			top: 65%;
			left: 86%;
		}

		&:nth-child(2) { // Gdańsk
			top: 15%;
			left: 37%;
		}

		&:nth-child(3) { // Kraków
			top: 80%;
			left: 52%;
		}

		&:nth-child(4) { // Lubin
			top: 58%;
			left: 15%;
		}

		&:nth-child(5) { // Olsztyn
			top: 26%;
			left: 57%;
		}

		&:nth-child(6) { // Piotrków Trybunalski
			top: 58%;
			left: 35%;
		}

		&:nth-child(7) { // Szczecin
			top: 32%;
			left: 2%;
		}

		&:nth-child(8) { // Toruń
			top: 35%;
			left: 36%;
		}

		&:nth-child(9) { // Warszawa
			top: 48%;
			left: 60%;
		}
	}

	.active {
		color: @text-1;

		&:after {
			border-color: @text-1;
			background-color: @text-1;
		}
	}
}

.widget {
	h2, h3 {
		margin-bottom: @padding-mobile + @padding-mini;
	}
}

.minW(@desktop-lg, {
	.contact-item {
		margin-bottom: @padding-desktop;
	}

	.contact-form {
		label {
			flex: 1.2;
		}
	}
});

.maxW(@mobile-max, {

	.widget {
		h2, h3 {
			text-align: center;
		}
	}

	.contact-item {
		text-align: center;
	}

	.contact-form {
		.button {
			.button-large;
			width: 100%;
		}
	}

	.headquarters-map {
		margin-left: -@padding-mobile;
		margin-right: -@padding-mobile;

	}

});

.minMax(@mobile-max + 1px, @tablet-md, {
	.headquarters-map {
		margin-left: -@padding-tablet;
		margin-right: -@padding-tablet;
	}
});

.minMax(@tablet-md, @tablet-max, {
	// .contact-items {
	// 	display: flex;
	// }

	// .contact-item {
	// 	width: calc(~"50% - @{padding-mobile}");
	// }

});

.minMax(@tablet-max + 1px, @desktop-sm - 1px, {
	.contact-items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.contact-item {
		width: calc(~"50% - @{padding-mobile}");
	}

});

// .minW(@tablet-md, {
// 	.headquarters-map {
// 		height: 300px;
// 	}
// });

.minMax(@desktop-md,@desktop-lg, {
	.contact-items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// column-count: 2;
	}

	.contact-item {
		display: inline-block;
		width: calc(~"50% - @{padding-mobile}");
	}

});

.regions-map {
	position: relative;
	width: 100%;
	max-width: 400px;
	margin: auto;

	.region {
		transition: fill @transition-short;
		cursor: pointer;
		position: relative;
        -webkit-tap-highlight-color: transparent;
    }

    #obrys {
        stroke: @text-1;
    }

	.active, .region:hover {
		fill: #F4F0F0;
	}
}

.region-labels {
	pointer-events: none;
	font-family: @font-2;
	color: @text-1;
	font-weight: 700;
	font-size: 3.2vw;
	line-height: 1.2;

	li {
		position: absolute;

		&:nth-child(1) {
			top: 30%;
			left: 15%;
		}

		&:nth-child(2) {
			top: 20%;
			left: 60%;
		}

		&:nth-child(3) {
			top: 51%;
			left: 51%;
			max-width: 50px;
		}

		&:nth-child(4) {
			top: 53%;
			left: 20%;
			max-width: 50px;
		}

		&:nth-child(5) {
			top: 76%;
			left: 56%;
		}
	}
}

.region-list {
	margin-bottom: @padding-tablet;
	h2 {
		cursor: pointer;
	}

	li {
		display: none;

		&.active {
			display: block;
		}
	}
}

.cms-siec-dystrybucji {
    .std {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h1 {
            width: 100%;
        }
    }
}

.minW(@mobile-max, {
	.region-labels {
		font-size: 1.6rem;
	}

});

.maxW(@ipad-v, {
	.regions-map {
		order: 1;
	}

	.region-list {
		order: 2;
	}

	.cms-siec-dystrybucji {
        .std {
            flex-direction: column;
        }
	}
});

.minW(@ipad-v,  {
	.region-list {
		width: calc(~"100% - 400px");
		float: left;
		clear: left;

	}
	.regions-map {
		width: 400px;
		float: right;
		clear: none;
	}
});
