.opineo-side-slider-widget {
	z-index: 50 !important;
}
.field-recaptcha {
	display: none;
}
.lac-notification-sticky {
	z-index: 1;
}
.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}
.d-flex {
	display: flex;
	gap: 10px;
	align-items: center;
	&.a-top {
		align-items: start;
	}
	&.j-space-between {
		justify-content: space-between;
	}
}
