@mobile-add-to-cart-height: 75px;

.product-view {
  .content;
  position: relative;

  .attribute.sku,
  .category,
  .availability,
  .packaging {
    margin-bottom: @padding-mobile;

    .type {
      .attribute-label();
    }

    .value {
      .attribute-value();
    }
  }

  .product.attribute.sku {
    display: block;
  }

  .index-upsell {
    min-width: 100px;

    .type {
      .attribute-label();
    }

    .value {
      .attribute-value();
    }
  }

  .std {
    font-size: 1.4rem;
    color: @text-2;

    ul {
      font-weight: 300;
      color: @product-info-list;
      font-size: 14px;
    }
  }

  .product-item-photo,
  .product-image .owl-item {
    height: 300px;
  }

  .product-image,
  .product-item-photo {
    margin-bottom: @padding-mobile;
  }

  .product-essential {
    .clearfix;
  }

  .availability {
    color: #999;

    .icon {
      stroke: #999;
      height: 14px;
      width: 22px;
      margin-bottom: -1px;
    }
  }
}

.gallery-placeholder,
.fotorama__fullscreen {
  .fotorama__stage .fotorama {
    &__fullscreen-icon {
      &:active {
        background-color: transparent;
      }

      &:after {
        box-shadow: none;
      }
    }
  }

  .fotorama__nav-wrap .fotorama {
    &__thumb {
      border: 1px solid @border-light;
    }

    &__thumb-border {
      border: 1px solid @text-2;
    }

    &__nav--thumbs {
      text-align: center;
    }
  }
}

.product-data {
  .list-reset;

  li {
    display: flex;
    flex-direction: column;
    padding: @padding-mobile;

    &:not(:last-child) {
      border-bottom: 1px solid @rgba-black;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .label {
    color: @text-1;
    font-weight: 700;
    text-align: center;
  }

  .value {
    text-align: center;
  }
}

.product-additional {
  .list-reset;
  font-family: @font-2;
  line-height: 1.2;

  li {
    padding: @padding-mobile 0;
    margin: 0;
    overflow: hidden;
    font-weight: 700;

    &:not(:last-child) {
      border-bottom: 1px solid @rgba-black;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: @text-1;
    }
  }

  .thumbnail {
    margin-right: @padding-mobile;
  }

  .label {
    color: @text-1;
    font-size: 1.6rem;
  }

  .price {
    text-align: right;
    flex: 1;
    font-size: 2rem;
    white-space: nowrap;
  }

  .thumbnail {
    width: 100px;
    height: 80px;
  }


}

.product-img-box {
  position: relative;

  .labels {
    .align-h();
    top: -1rem;
    z-index: 2;
  }

  .owl-carousel {
    touch-action: manipulation;
  }

  .owl-nav {
    display: none;
  }
}

.product-image-thumbs {
  .list-reset;
  display: flex;
  flex-wrap: wrap;

  li {
    .thumbnail;
  }

  img {
    pointer-events: none;
  }
}

.product-item-photo {
  margin: auto;

  .thumb-link {
    padding: 0;
  }

  .product-image-gallery {

    .owl-item {
      display: flex;
      justify-content: center;
      align-items: center;

      .gallery-image {
        cursor: zoom-in;
      }
    }

    img {
      width: auto;
      max-height: 100%;
      align-self: center;
      flex-shrink: 0;
    }
  }

}

.product-collateral {
  margin-top: @padding-desktop;
}

.list-files,
.block-files ul {
  .list-reset;
  line-height: 1.3;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: @padding-mobile 0;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 1.5rem;
      height: 18px;
      width: 16px;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;
    }

    &[href$='.pdf'], &.pdf-icon {
      &:before {
        .icon('pdf');
      }
    }
  }

  li {
    border-top: 1px solid @rgba-black;

    &:last-child {
      border-bottom: 1px solid @rgba-black;
    }
  }

  .icon {
    stroke: @red;
    fill: @red;
    margin-right: @padding-mobile;
    height: 20px;
    width: 18px;
  }
}

.minW(@mobile-md, {
  .toggle-tabs {

    .tab-item {
      width: auto;
    }

    .owl-item {
      .tab-item {
        width: 100%;
      }
    }
  }
});

.maxW(@ipad-v - 1, {

  .fotorama__nav--dots .fotorama__nav__frame {
    height: 14px;
    width: 14px;
  }

  .fotorama__nav__frame--dot {
    margin: .75rem;

    .fotorama__dot {
      background-color: @border-light;
      border-radius: 50%;
      border: 0;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.fotorama__active {
      .fotorama__dot {
        background-color: @yellow-dark;
      }
    }
  }

  .product-image-thumbs {
    justify-content: center;
    align-items: center;
    padding-top: @padding-mobile;
    padding-bottom: @padding-mobile;

    li {
      background-color: @border-light;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 0;
      margin: .75rem;

      img {
        display: none;
      }
    }

    .active {
      background-color: @yellow-dark;
    }

    img {
      display: none;

    }
  }

  .product-additional {

    .thumbnail {
      margin: auto;
      margin-bottom: @padding-mobile;
    }

    .label,
    .price,
    .index-upsell {
      display: block;
      text-align: center;
    }
  }

});

.minW(@mobile-max, {

  .product-image-thumbs {
    li {
      min-width: 40px;
      max-width: 100px;
      height: 60px;
    }
  }

  .product-additional {

    .thumbnail {
      float: left;
    }

    .label,
    .price,
    .index-upsell {
      float: right;
      clear: right;
    }
  }

  .toggle-tabs {
    display: flex;
  }

  .index-upsell {
    text-align: right;
  }
});

.maxW(@ipad-v, {
  .toggle-tabs {

    .tab-item {
      &:after {
        bottom: 0;
      }
    }
  }

  .product-additional {
    .label {
      margin-bottom: @padding-mini;
    }
  }

  .fotorama__nav__shaft {
    margin-top: @padding-mobile;
  }
});

.catalog-product-view {
  .page-title-wrapper {
    margin-bottom: .67em;
  }

  h1 {
    margin-bottom: 0;
    display: inline;
  }
}

.page-title-wrapper {
  .label {
    padding: 0.4rem .8rem;
    width: auto;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
    font-family: @font-1;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    margin-left: 1rem;
    position: relative;
  }

  .promo {
    .label-promo();
  }

  .new {
    .label-new();
  }

  .sale {
    .label-sale();
  }

  .bestseller {
    .label-bestseller();
  }
}

.product-extras {
  .block {
    margin-bottom: 0;
  }
}

.maxW(@tablet-max, {
  .catalog-product-view {
    padding-bottom: @mobile-add-to-cart-height;

    .sidebar-additional {
      display: block;

      .block {
        display: none;
      }

      .block-order {
        display: block;
      }
    }

    .block-order {
      position: fixed !important;
      top: auto !important;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      border-radius: 0;
      padding-top: 0;
      background-color: @white;
      z-index: 10;
      height: @mobile-add-to-cart-height;

      .button {
        width: auto;
        padding-right: @padding-mobile;
        padding-left: @padding-mobile;
      }

      .product-add-form {
        width: 100%;
      }

      .box-tocart .fieldset {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .field.qty {
          margin-bottom: 0;
          margin-top: 1rem;
          display: flex;
          align-items: center;

          .maxW(@mobile-max, {
            margin-right: @padding-mobile;
          });
        }

        .label {
          margin: 0;
          padding: 0;
        }
      }

      .block-content {
        box-shadow: none;
        padding-bottom: @padding-mobile;
        border-top: 1px solid @border-light;

        &:before {
          height: 0;
        }

      }

      .label-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: normal;
        height: 2.4rem;

        .old-price,
        .price-excluding-tax,
        .price-label:before,
        .price-label,
        .tax-label {
          display: none;
        }

        .price-including-tax {
          padding: 0;
          margin: 0;

          &.price-wrapper {
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &:before {
              min-height: auto;
              padding: 0;
              margin: 0;
              color: @white;
              font-weight: 700;
              white-space: nowrap;
            }
          }
        }

        .price-final_price {
          padding: 0;
          margin: 0;

          .price,
          .price-label {
            color: @white;
            font-size: 1.2rem;
            font-family: @font-1;
          }
        }

        .label {
          font-weight: 400;
          text-align: left;
        }

        .value,
        .label {
          flex: 1;
          height: 100%;
        }
      }

      .btn-cart {
        &:hover,
        &:active,
        &:focus {
          .price-final_price {
            .price,
            .price-label,
            .price-wrapper:before {
              color: @text-1;
            }
          }
        }
      }
    }
  }

  .product-view {
    .product-img-box {
      .labels {
        display: flex;
        justify-content: space-between;

        li {
          &:not(:first-child) {
            margin-left: @padding-mini;
          }
        }
      }
    }
  }

  .product-consultation-buttons {
    .price-placeholder {
      font-family: @font-2;
      font-weight: 700;
    }

    .popup-open {
      margin-top: @padding-mini;
    }
  }
});

.maxW(@mobile-max - 1px, {
  .product-consultation-buttons {
    padding-top: @padding-mini;
  }
});

.minMax(@mobile-max, @tablet-max, {
  .product-consultation-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-basis: 100%;
    padding-top: @padding-mobile;

    .popup-open {
      margin: 0;
    }
  }
});

.minW(@ipad-v, {

  .product-image-thumbs {

    li {
      width: calc(~"25% - .4rem");
      margin: .2rem;
    }
  }

  .product-view {
    overflow: hidden;
  }

  .product-data {

    li {
      flex-direction: row;
      justify-content: space-between;
    }

    .label {
      text-align: left;
      margin-right: @padding-mobile;
    }

    .value {
      text-align: right;
    }
  }

  .product-additional {
    a {
      display: flex;
      justify-content: stretch;
      align-items: center;
    }

    .label {
      flex: 1;
    }
  }

  .media {
    width: calc(~"50% - @{padding-mobile}");
    float: left;
  }

  .product-info-main {
    width: calc(~"50% - @{padding-mobile}");
    float: right;
  }

  .product-collateral {
    clear: both;
  }

  .product-extras {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .block {
      width: calc(~"50% - @{padding-tablet} / 2");
      display: flex;
      flex-direction: column;

      .block-content {
        flex-grow: 2;
      }
    }

    .block-videos {
      width: 100%;
    }
  }

  .toggle-tabs {
    .tab-item {
      padding-left: @padding-tablet;
      padding-right: @padding-tablet;
    }
  }
});

.minMax(@ipad-v, @desktop-sm, {
  .product-extras {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .block {
      width: calc(~"50% - @{padding-tablet} / 2");
    }

    .block-videos {
      width: 100%;
    }
  }
});

.minW(@desktop-lg, {
  .product-image-thumbs {
    li {
      height: 80px;
    }
  }

  .toggle-tabs {
    .tab-item {
      min-height: 70px;
    }
  }
});
