.double-bounce1, .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: @yellow-dark;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
	animation-delay: -1.0s;
}

.add-to-cart-loading-ajax {
	background-color: fade(white, 85%);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 101;

	.spinner {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.product-add-form,
.item-actions {
    .btn-cart:disabled {
        background-color: @red;
        opacity: 1;
        position: relative;

        span {
            visibility: hidden;
        }

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 9px);
            left: calc(50% - 9px);
            width: 18px;
            height: 18px;
            border: 2px solid rgba(255, 255, 255, .6);
            border-top-color: @white;
            border-radius: 50%;
            animation: loading 1s ease infinite;
        }
    }
}

@keyframes sk-bounce {
	0%, 100% {
		transform: scale(0.0);
	} 50% {
		transform: scale(1.0);
	}
}

@keyframes loading {
    to {
        transform: rotate(1turn);
    }
}
