
.opc-progress-bar {
	margin: auto;
	padding: 4rem;
	max-width: 950px;

	.opc-progress-bar-item {
        > span {
            font-family: @font-2;
            line-height: 1.1;
            color: @text-3;

            &:before {
                background-color: @text-3;
            }

            &:after {
                color: @text-3;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
            }
        }

        &:before {
            background-color: @text-3;
        }

        &._active {
            > span {
                color: @text-1;

                &:before {
                    background-color: @yellow-dark;
                }

                &:after {
                    color: @text-1;
                }
            }

            &:before {
                background-color: @yellow-dark;
            }
        }
	}
}
